import Api from '@/services/Api'
import Errors from '@/services/Error'
// import { mapMutations } from 'vuex'
import store from '@/store'

export default {
    requestStatus: 0,
    requestError: 0,
    userAuth(username, password, email, method, options) {
        var promise = new Promise((resolve, reject) => {
            var authRequest = {
                'username': username,
                'password': password,
                'email': email
            }

            var err
            switch (method) {
                case "GET":
                    err = new Error("GET is not a valid method on this endpoint!")
                    err.name = Errors.API.UserErrors.InvalidMethodErr
                    reject(err)
                    return promise
                case "POST":
                    authRequest.email = ""
                    break
                case "UPDATE":
                    err = new Error("UPDATE is not a valid method on this endpoint!")
                    err.name = Errors.API.UserErrors.InvalidMethodErr
                    reject(err)
                    return promise
                case "PUT":
                    // do nothing
                    break
                default:
                    err = new Error(method + " is not a valid method on this endpoint!")
                    err.name = Errors.API.UserErrors.InvalidMethodErr
                    reject(err)
                    return promise
            }

            var request = Api.InitRequest(Api.usersEndpoint(), method, authRequest, "")


            var self = this;

            // make request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Unauthorized")
                        err.name = Errors.API.Unauthorized.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 403) {
                        err = new Error("Forbidden")
                        err.name = Errors.API.Forbidden.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 400) {
                        err = new Error("Bad request")
                        err.message = data.status_message
                        err.name = Errors.API.BadRequest.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 409) {
                        err = new Error("This username or email already exists")
                        err.name = Errors.API.Conflict.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {

                        switch (method) {
                            case "POST":
                                // Set the user data in localStorage
                                store.commit('setUserAuth', {
                                    username: authRequest.username,
                                    token: data.request_data.user_auth_key,
                                    saveLocalStorage: options.saveLocalStorage
                                })
                                break
                            default:
                                // do nothing
                                break
                        }

                        resolve(data)
                        return promise

                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise;
    },
    userProfile(username, password, email, method) {
        var promise = new Promise((resolve, reject) => {
            if (store.state.user.username == null || store.state.user.token == null) {
                var err = new Error("Couldn't send request, no username and/or token")
                err.name = Errors.API.Unauthorized.name
                reject(err)
                return promise
            }

            var apiKey = store.state.user.username + ':' + store.state.user.token

            var authRequest = {
                'username': username,
                'password': password,
                'email': email
            }

            switch (method) {
                case "GET":
                    authRequest = ""
                    break
                case "PATCH":
                    // do nothing
                    break
                default:
                    err = new Error(method + " is not a valid method on this endpoint!")
                    err.name = Errors.API.UserErrors.InvalidMethodErr
                    reject(err)
                    return promise
            }

            var request = Api.InitRequest(Api.usersProfileEndpoint(), method, authRequest, apiKey)

            var self = this;

            // make request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        var err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 400) {
                        err = new Error("Bad request")
                        err.name = Errors.API.BadRequest.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 409) {
                        err = new Error("Conflict")
                        err.name = Errors.API.Conflict.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })

        return promise

    },
    userAPI(apiUUID, payload, method) {
        var promise = new Promise((resolve, reject) => {
            var apiKey = store.state.user.username + ':' + store.state.user.token
            var request = Api.InitRequest(Api.usersEndpoint() + "/api" + "/" + apiUUID, method, payload, apiKey)
            var self = this;

            var err

            // make request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    userResolve(username) {
        var promise = new Promise((resolve, reject) => {
            var apiKey = store.state.user.username + ':' + store.state.user.token
            var request = Api.InitRequest(Api.usersResolveEndpoint() + username, "GET", "", apiKey)
            var self = this;

            // eslint-disable-next-line
            var err

            // make request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 403) {
                        err = new Error("Forbidden")
                        err.name = Errors.API.Forbidden.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 404) {
                        err = new Error("NotFound")
                        err.name = Errors.API.NotFound.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    }
}