<template>
  <section>
    <slot>
      <div class="content has-text-grey has-text-centered">
        <p>
          <b-icon
            :icon="icon"
            :pack="iconPack"
            size="is-large"
          />
        </p>
        <p>{{ message }}</p>
      </div>
    </slot>
  </section>
</template>

<script>
export default {
  name: "EmptyMessage",
  props: {
    message: String,
    icon: String,
    iconPack: String
  },
  data() {
    return {};
  }
};
</script>