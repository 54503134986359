import Vue from 'vue'
import Vuex from 'vuex'
import { SnackbarProgrammatic as Snackbar } from 'buefy'

import Errors from '@/services/Error'
import OrganisationsApi from '@/services/Organisations'
import DevicesApi from '@/services/Devices'
import TelemetryApi from '@/services/Telemetry'
import PermissionsApi from '@/services/Permissions'
import NotificationsApi from '@/services/Notifications'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appVersion: "v"+(process.env.VUE_APP_VERSION || '0'),
    apiBaseURL: process.env.VUE_APP_API_BASE_URL,
    apiWSBaseURL: process.env.VUE_APP_API_WS_BASE_URL,
    user: {
      username: null,
      token: null
    },
    notificationFlag: false,
    lastNotificationTimestamp: null,
    debuggingEnabled: process.env.VUE_APP_DEBUGGING_DEFAULT == 'true',
    loginActive: false,
    popupDisable: false,
    loadingActive: false,
    currentTime: null,
    statusPollLocked: false,
    dashboardStates: {
      changeAuthActive: false,
      organisations: [],
      devices: [],
      shareddevices: [],
      devicesshared: [],
      permissions: [],
      status: [],
      notifications: [],
      telemetryDebuggingMessages: []
    },
    notificationConnectionReference: null,
    telemetryDebuggingSubscribed: null,
  },
  getters: {
    appVersion: (state) => {
      return state.appVersion
    },
    UserLoggedIn: state => {
      if (state.user.username == null || state.user.token == null) {
        return false
      } else {
        return true
      }
    },
    externalServiceQueryString: state => {
      if (state.user.username == null || state.user.token == null) {
        return null
      }
      return "?username=" + state.user.username + "&api_key=" + state.user.token
    },
    isPopupDisabled: state => {
      if (state.popupDisable == null || !state.popupDisable) {
        return false
      } else {
        return true
      }
    },
    notificationCount: state => {
      var notificationCount = 0;

      /*
      if (Tools.hasPermission(state.dashboardStates.permissions, 'site-admin')) {
        notificationCount++
      }*/

      if ((state.popupDisable == null || !state.popupDisable)) {
        notificationCount++
      }

      notificationCount += state.dashboardStates.notifications.length

      return notificationCount
    }
  },
  mutations: {
    setUserAuth: (state, payload) => {
      state.user.username = payload.username
      state.user.token = payload.token

      if (payload.username != null && payload.saveLocalStorage) {
        localStorage.username = payload.username
        localStorage.token = payload.token
      } else {
        localStorage.removeItem('username')
        localStorage.removeItem('token')
      }
    },
    getUserAuthLocalStorage: (state) => {
      if (localStorage.username && localStorage.token) {
        state.user.username = localStorage.username
        state.user.token = localStorage.token
      }
    },
    setPopupDisable: (state, payload) => {
      state.popupDisable = payload
      localStorage.popupDisable = payload
    },
    getPopupDisableLocalStorage: (state) => {
      if (localStorage.popupDisable != null) {
        state.popupDisable = localStorage.popupDisable
      }
    },
    updateLoginActive: (state, payload) => {
      state.loginActive = payload
    },
    updateLoadingActive: (state, payload) => {
      state.loadingActive = payload
    },
    updateCurrentTime: (state) => {
      state.currentTime = new Date
    },
    updateDashboardState: (state, payload) => {
      state.dashboardStates[payload.element] = payload.content
    },
    statusPollSetLocked: (state, payload) => {
      state.statusPollLocked = payload
    },
    /**
     * updateNotificationFlag() - Update notificationFlag content
     * @state: Global Vuex state
     * @payload: Boolean value for setting the notificationFlag
     */
    updateNotificationFlag: (state, payload) => {
      state.notificationFlag = payload
    },
    /**
     * setNotificationClear() - Clear notifications up to the current timestamp locally
     * @state: Global Vuex state
     */
    setNotificationClear: (state) => {
      // TODO: Implement this functionallity server-side
      state.lastNotificationTimestamp = new Date()
      localStorage.lastNotificationTimestamp = state.lastNotificationTimestamp

      state.dashboardStates.notifications = state.dashboardStates.notifications.filter(unreadMessage => {
        if (state.lastNotificationTimestamp == null) {
          return true
        }
        return new Date(unreadMessage.date_created).getTime() > new Date(state.lastNotificationTimestamp).getTime()
      })
    },
    /**
     * getNotificationClearLocalStorage() - Get the state of lastNotificationTimestamp from Local Storage
     * @state: Global Vuex state
     */
    getNotificationClearLocalStorage: (state) => {
      if (localStorage.lastNotificationTimestamp != null) {
        state.lastNotificationTimestamp = localStorage.lastNotificationTimestamp
      }
    },
    /**
     * setNotificationConnectionReference() - Set the state of notificationConnectionReference object to the payload
     * @state: Global Vuex state
     */
    setNotificationConnectionReference: (state, payload) => {
      state.notificationConnectionReference = payload
    },
    /**
     * setTelemetryDebuggingSubscribed() - Set the state of telemetryDebuggingSubscribed object to the payload
     * @state: Global Vuex state
     */
    setTelemetryDebuggingSubscribed: (state, payload) => {
      state.telemetryDebuggingSubscribed = payload
    },
  },
  actions: {
    startCurrentTime({ commit }) {
      commit('updateCurrentTime')
      setInterval(() => {
        commit('updateCurrentTime')
      }, 1000 * 1) // update time every second
    },
    startStatusPoll({ dispatch, state }) {
      dispatch('getStatus')
      setInterval(() => {
        if (!state.statusPollLocked) {
          dispatch('getStatus')
        }
      }, 1000 * 20)
    },
    clearUserAuth: ({ commit }) => {
      commit('setUserAuth', {
        username: null,
        token: null
      })
    },
    /**
     * throwGlobalNotification() - Throw a global notification modal
     * @commit: Commit handler
     * @state: Global Vuex state (only read)
     * @message: Notification modal message
     * @queue: Queue the messages, do not suppress
     * @isError: Does the message express an error
     *
     * Throw a global notification and clear it once the user acknowledges
     * the message.
     * A new notification can only be thrown, once the former is cleared (throw is suppressed).
     *
     * Return: A boolean is returned to indicate, whether a previous error exists.
     */
    throwGlobalNotification: ({ commit, state }, object) => {
      let debuggingEnabled = false;

      // exit the function in case the flag is raised
      if (state.notificationFlag) {
        return true
      }

      if (object.hasOwnProperty('debug')) {
        if (object.debug && !state.debuggingEnabled) {
          // ignore the notification
          return true
        } else if (object.debug && state.debuggingEnabled) {
          debuggingEnabled = true;
        }
      }

      // set notificationFlag
      commit('updateNotificationFlag', true)

      // open a snackbar
      const snackbar = Snackbar.open({
        message: object.message,
        indefinite: true,
        queue: object.queue,
        type: debuggingEnabled ? 'is-warning' : object.isError ? 'is-danger' : 'is-info',
        position: 'is-bottom-right',
        actionText: 'Ok',
        onAction: () => {
          commit('updateNotificationFlag', false)
        }
      })

      // close the snackbar and set notificationFlag after x seconds
      setTimeout(() => {
        snackbar.close()
        commit('updateNotificationFlag', false)
      }, object.isError ? 15000 : 5000);

      return false
    },
    getOrganisations: ({ commit, dispatch }) => {
      OrganisationsApi.organisations("", "", "GET").then(payload => {
        if (payload == null || payload == undefined) {
          payload = []
        }
        commit('updateDashboardState', {
          element: "organisations",
          content: payload
        })
      }).catch(err => {
        if (err.name == Errors.API.Unauthorized.name) {
          dispatch('throwGlobalNotification', { message: "You have to be logged in to access this data.", queue: false, isError: true })
        } else if (err.name == Errors.API.UnexpectedErr.name) {
          dispatch('throwGlobalNotification', { message: "An unexpected error has occurred.", queue: false, isError: true })
        } else if (err.name == Errors.API.ServerInternalErr.name) {
          dispatch('throwGlobalNotification', { message: "Internal server error has occurred.", queue: false, isError: true })
        } else {
          dispatch('throwGlobalNotification', { message: 'Connection to the server was lost.', queue: false, isError: true })
        }
        // eslint-disable-next-line
        console.log(err);
      })
    },
    getDevices: ({ commit, dispatch }) => {
      DevicesApi.devices("", "", "", false, "GET").then(payload => {
        if (payload == null || payload == undefined) {
          payload = []
        }
        commit('updateDashboardState', {
          element: "devices",
          content: payload
        })
      }).catch(err => {
        if (err.name == Errors.API.Unauthorized.name) {
          dispatch('throwGlobalNotification', { message: "You have to be logged in to access this data.", queue: false, isError: true })
        } else if (err.name == Errors.API.UnexpectedErr.name) {
          dispatch('throwGlobalNotification', { message: "An unexpected error has occurred.", queue: false, isError: true })
        } else if (err.name == Errors.API.ServerInternalErr.name) {
          dispatch('throwGlobalNotification', { message: "Internal server error has occurred.", queue: false, isError: true })
        } else {
          dispatch('throwGlobalNotification', { message: 'Connection to the server was lost.', queue: false, isError: true })
        }
        // eslint-disable-next-line
        console.log(err);
      })
    },
    getDevicesShared: ({ commit, dispatch }) => {
      DevicesApi.devices("", "", "", true, "GET").then(payload => {
        if (payload == null || payload == undefined) {
          payload = []
        }
        commit('updateDashboardState', {
          element: "devicesshared",
          content: payload
        })
      }).catch(err => {
        if (err.name == Errors.API.Unauthorized.name) {
          dispatch('throwGlobalNotification', { message: "You have to be logged in to access this data.", queue: false, isError: true })
        } else if (err.name == Errors.API.UnexpectedErr.name) {
          dispatch('throwGlobalNotification', { message: "An unexpected error has occurred.", queue: false, isError: true })
        } else if (err.name == Errors.API.ServerInternalErr.name) {
          dispatch('throwGlobalNotification', { message: "Internal server error has occurred.", queue: false, isError: true })
        } else {
          dispatch('throwGlobalNotification', { message: 'Connection to the server was lost.', queue: false, isError: true })
        }
        // eslint-disable-next-line
        console.log(err);
      })
    },
    getSharedDevices: ({ commit, dispatch }) => {
      DevicesApi.sharedDevices("", "", "", "", "GET").then(payload => {
        if (payload == null || payload == undefined) {
          payload = []
        }
        commit('updateDashboardState', {
          element: "shareddevices",
          content: payload
        })
      }).catch(err => {
        if (err.name == Errors.API.Unauthorized.name) {
          dispatch('throwGlobalNotification', { message: "You have to be logged in to access this data.", queue: false, isError: true })
        } else if (err.name == Errors.API.UnexpectedErr.name) {
          dispatch('throwGlobalNotification', { message: "An unexpected error has occurred.", queue: false, isError: true })
        } else if (err.name == Errors.API.ServerInternalErr.name) {
          dispatch('throwGlobalNotification', { message: "Internal server error has occurred.", queue: false, isError: true })
        } else {
          dispatch('throwGlobalNotification', { message: 'Connection to the server was lost.', queue: false, isError: true })
        }
      })
    },
    getStatus: ({ commit, dispatch, getters }) => {
      if (getters.UserLoggedIn) {
        commit('statusPollSetLocked', true)
        TelemetryApi.telemetryStatus(true, true, false, true)
          .then(payload => {
            if (payload == null || payload == undefined) {
              payload = []
            }
            commit('statusPollSetLocked', false)
            commit('updateDashboardState', {
              element: "status",
              content: payload
            })
          })
          .catch(err => {
            if (err.name == Errors.API.Unauthorized.name) {
              dispatch('throwGlobalNotification', { message: "You have to be logged in to access this data.", queue: false, isError: true })
            } else if (err.name == Errors.API.UnexpectedErr.name) {
              dispatch('throwGlobalNotification', { message: "An unexpected error has occurred.", queue: false, isError: true })
            } else if (err.name == Errors.API.ServerInternalErr.name) {
              dispatch('throwGlobalNotification', { message: "Internal server error has occurred.", queue: false, isError: true })
            } else {
              dispatch('throwGlobalNotification', { message: 'Connection to the server was lost.', queue: false, isError: true })
            }

            commit('statusPollSetLocked', false)
          });
      }
    },
    getUserPermissions: ({ commit, dispatch }) => {
      PermissionsApi.userPermissions("", "", "", "GET").then(payload => {
        if (payload == null || payload == undefined) {
          payload = []
        }
        commit('updateDashboardState', {
          element: "permissions",
          content: payload
        })
      }).catch(err => {
        if (err.name == Errors.API.Unauthorized.name) {
          dispatch('throwGlobalNotification', { message: "You have to be logged in to access this data.", queue: false, isError: true })
        } else if (err.name == Errors.API.UnexpectedErr.name) {
          dispatch('throwGlobalNotification', { message: "An unexpected error has occurred.", queue: false, isError: true })
        } else if (err.name == Errors.API.ServerInternalErr.name) {
          dispatch('throwGlobalNotification', { message: "Internal server error has occurred.", queue: false, isError: true })
        } else {
          dispatch('throwGlobalNotification', { message: 'Connection to the server was lost.', queue: false, isError: true })
        }
      })
    },
    sendNotificationsEmailEnabled: ({ state }, object) => {
      if (state.notificationConnectionReference != null) {
        state.notificationConnectionReference.send(JSON.stringify({ "notifications_enabled": object }))
      }
    },
    subscribeNotifications: ({ commit, dispatch, state }) => {
      if (state.notificationConnectionReference != null) {
        state.notificationConnectionReference.close(1000)
      }

      NotificationsApi.subscribeNotificationsConnector().then(connection => {
        commit('setNotificationConnectionReference', connection)
        connection.onopen = function () { }

        connection.onclose = function () {
          // the WebSocket was closed gracefully
          dispatch('throwGlobalNotification', { message: "Connection to the server was interrupted.", queue: false, isError: true })
        }

        connection.onerror = function () {
          // the WebSocket connection was dropped
          dispatch('throwGlobalNotification', { message: "Connection to the server was interrupted.", queue: false, isError: true })
        }

        connection.onmessage = function (event) {
          let message = JSON.parse(event.data)

          if (Array.isArray(message)) {
            // TODO: Implement this functionallity server-side
            // Scan the array and remove older messages
            let unreadMessages = [...message]
            unreadMessages = unreadMessages.filter(unreadMessage => {
              if (state.lastNotificationTimestamp == null) {
                return true
              }
              return new Date(unreadMessage.date_created).getTime() > new Date(state.lastNotificationTimestamp).getTime()
            })

            commit('updateDashboardState', {
              element: "notifications",
              content: unreadMessages
            })
          } else {
            if (message == null) {
              // Do not add the message, it's null
              return
            }
            let notifications = [...state.dashboardStates.notifications]
            notifications.push(message)
            commit('updateDashboardState', {
              element: "notifications",
              content: notifications
            })
            dispatch('throwGlobalNotification', { message: "Notification: " + message.message, queue: true, isError: false })
          }
        }
      }).catch(err => {
        dispatch('throwGlobalNotification', { message: "An error has occurred: " + err.name, queue: false, isError: true })
      })
    },
    subscribeTelemetryDebugging: ({ commit, dispatch, state }) => {
      TelemetryApi.subscribeTelemetryDebuggingConnector().then(connection => {
        connection.onopen = function () {
          commit('setTelemetryDebuggingSubscribed', true)
        }

        connection.onclose = function () {
          // the WebSocket was closed gracefully
          commit('setTelemetryDebuggingSubscribed', false)
          dispatch('throwGlobalNotification', { message: "Connection to the server was interrupted.", queue: false, isError: true })
        }

        connection.onerror = function () {
          // the WebSocket connection was dropped
          commit('setTelemetryDebuggingSubscribed', false)
          dispatch('throwGlobalNotification', { message: "Connection to the server was interrupted.", queue: false, isError: true })
        }

        connection.onmessage = function (event) {
          let message = JSON.parse(event.data)

          if (Array.isArray(message)) {
              commit('updateDashboardState', {
              element: "telemetryDebuggingMessages",
              content: message
            })
          } else {
            if (message == null) {
              // Do not add the message, it's null
              return
            }
            let telemetryDebuggingMessages = [...state.dashboardStates.telemetryDebuggingMessages]
            telemetryDebuggingMessages.push(message)
            commit('updateDashboardState', {
              element: "telemetryDebuggingMessages",
              content: telemetryDebuggingMessages
            })
            dispatch('throwGlobalNotification', { message: "Telemetry debugging: New packet has arrived", queue: true, isError: false })
          }
        }
      }).catch(err => {
        dispatch('throwGlobalNotification', { message: "An error has occurred: " + err.name, queue: false, isError: true })
      })
    }
  }
})
