import Api from '@/services/Api'
import Errors from '@/services/Error'
import ReconnectingWebSocket from 'reconnecting-websocket';
import store from '@/store'

export default {
    subscribeNotificationsConnector() {
        return new Promise((resolve, reject) => {
            if (store.state.user.username == null || store.state.user.token == null) {
                let err = new Error("Couldn't send request, no username and/or token")
                err.name = Errors.API.Unauthorized.name
                reject(err)
            }

            const urlProvider = async () => {
                var apiKey = store.state.user.username + ':' + store.state.user.token
                return Api.notificationsEndpoint() + "?api_key=" + apiKey
            }

            let connection = new ReconnectingWebSocket(urlProvider)

            resolve(connection)
        })
    }
}