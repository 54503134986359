import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

var forceReload = {
  reloadRoutes: [
    /*"home"*/ // old dirty fix
  ]
}

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'data',
      component: () => import('./views/Data.vue')
    },
    {
      path: '/status',
      name: 'status',
      component: () => import('./views/Status.vue')
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('./views/Administration.vue')
    },
    {
      path: '/organisations',
      name: 'organisations',
      component: () => import('./views/Organisations.vue')
    },
    {
      path: '/devicetypes',
      name: 'devicetypes',
      component: () => import('./views/Devices.vue')
    },
    {
      path: '/mydevices',
      name: 'mydevices',
      component: () => import('./views/SharedDevices.vue')
    },
    {
      path: '/devicemap',
      name: 'devicemap',
      component: () => import('./views/DeviceMap.vue'),
      props: (route) => ({ uuid: route.query.uuid })
    },
    {
      path: '/embed',
      name: 'embed',
      component: () => import('./views/Embed.vue'),
      props: (route) => ({
        user: route.query.user,
        apiKey: route.query.api_key,
        deviceEui: route.query.device_eui,
        deviceName: route.query.device_name,
        keys: route.query.keys
      })
    }
  ]
})

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    for (let i = 0; i < forceReload.reloadRoutes.length; i++) {
      if (forceReload.reloadRoutes[i] == to.name && forceReload.reloadRoutes[i] != from.name && from.name != null) {
        router.go()
      }
    }
    // Start the route progress bar.
    store.commit("updateLoadingActive", true);
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  store.commit("updateLoadingActive", false);
})

export default router;

