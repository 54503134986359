<template>
  <Modal
    title="Live Telemetry Debugger"
    :background-primary="true"
    button-icon-pack="fas"
    :button-icon="/* TODO: Enable, when supported. */ /*telemetryDebuggingSubscribed*/ true ? null : 'bug'"
    :button-text="/* TODO: Enable, when supported. */ /*telemetryDebuggingSubscribed*/ true ? null : 'Start debugging'"
    @click="subscribeTelemetryDebugging()"
    @close="$parent.close()"
  >
    <div class="is-live-telemetry-modal">
      <section class="is-live-telemetry-content">
        <b-message
          type="is-info"
          aria-close-label="Close message"
          class="is-live-telemetry-info"
        >
          You can debug and monitor the platform telemetry ingress below (live) to
          see how data is categorized/matched, parsed or denied.
        </b-message>
      </section>
      <div class="divider">
        LIVE TELEMETRY INGRESS
      </div>
      <section>
        <b-table
          ref="live-telemetry-table"
          class="is-live-telemetry-table"
          :data="
            dashboardState.telemetryDebuggingMessages.length == 0
              ? []
              : dashboardState.telemetryDebuggingMessages
          "
          :bordered="false"
          :striped="true"
          :narrowed="true"
          :hoverable="false"
          :loading="false"
          :focusable="false"
          :mobile-cards="false"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort-direction="desc"
          default-sort="date_created"
          :per-page="4"
          :paginated="true"
        >
          <template slot-scope="props">
            <b-table-column
              field="backend_driver"
              label="Backend Driver"
            >
              {{ props.row.backend_driver }}
            </b-table-column>
            <b-table-column
              field="ingress_authenticated"
              label="Ingress authenticated"
              centered
            >
              <status-indicator :status="props.row.ingress_authenticated ? 'positive' : 'negative'" />
            </b-table-column>

            <b-table-column
              field="delegated_organisation"
              label="Delegated organisation"
            >
              {{ props.row.delegated_organisation }}
            </b-table-column>

            <b-table-column
              field="parsing_successful"
              label="Parsing successful"
              centered
            >
              <status-indicator :status="props.row.parsing_successful ? 'positive' : 'negative'" />
            </b-table-column>

            <b-table-column
              field="matched_device"
              label="Matched device"
            >
              {{ props.row.matched_device }}
            </b-table-column>

            <b-table-column
              field="content"
              label="Parsed message content viewer"
            >
              <a>Open viewer</a>
            </b-table-column>

            <b-table-column
              field="date_received"
              label="Date Received"
              sortable
              centered
            >
              <span class="tag is-dark">{{ new Date(props.row.date_received).toLocaleString() }}</span>
            </b-table-column>

            <b-table-column
              field="date_processed"
              label="Date Processed"
              sortable
              centered
            >
              <span class="tag is-dark">{{ new Date(props.row.date_processed).toLocaleString() }}</span>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="access-point"
                    pack="mdi"
                    size="is-large"
                  />
                </p>
                <p>There is no live telemetry ingress, yet.</p>
                <p>Please start debugging to receive more messages.</p>
              </div>
            </section>
          </template>
        </b-table>
      </section>
      <section>
        <b-modal
          :active.sync="isTutorialActive"
          :can-cancel="false"
          has-modal-card
        >
          <DevicesOrganisationsDocs />
        </b-modal>
      </section>
    </div>
  </Modal>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import DevicesOrganisationsDocs from "@/components/docs/DevicesOrganisations.vue";
import EmptyMessage from "@/components/elements/EmptyMessage.vue";
import Modal from "@/components/elements/Modal.vue";
import Tools from "@/services/Tools.js";

export default {
  name: "UsersNotification",
  data() {
    return {
      isTutorialActive: false,
      connection: null,
    };
  },
  computed: {
    ...mapState({
      userState: "user",
      dashboardState: "dashboardStates",
    }),
    telemetryDebuggingSubscribed: {
      get() {
        return this.$store.state.telemetryDebuggingSubscribed;
      },
    },
  },
  components: {
    DevicesOrganisationsDocs,
    EmptyMessage,
    Modal,
  },
  methods: {
    ...mapMutations(["setPopupDisable", "setNotificationClear"]),
    ...mapGetters(["isPopupDisabled"]),
    ...mapActions({
      throwGlobalNotification: "throwGlobalNotification",
      subscribeTelemetryDebugging: "subscribeTelemetryDebugging",
    }),
    disablePopup() {
      this.setPopupDisable(true);
    },
    statusCodeBullet(statusCode) {
      if (statusCode == 0) {
        return "positive";
      } else if (statusCode == 1) {
        return "intermediary";
      } else if (statusCode == 2) {
        return "negative";
      }
    },
    statusCodeTooltip(statusCode) {
      if (statusCode == 0) {
        return "OK";
      } else if (statusCode == 1) {
        return "Warning";
      } else if (statusCode == 2) {
        return "Critical";
      } else {
        return "Unknown status!";
      }
    },
    hasPermission: Tools.hasPermission,
    findByField: Tools.findByField,
    // eslint-disable-next-line
    onCopy(e) {
      this.throwGlobalNotification({
        message: "Copied to clipboard",
        queue: true,
        isError: false,
      });
    },
    // eslint-disable-next-line
    onError(e) {
      this.throwGlobalNotification({
        message: "Failed to copy to clipboard",
        queue: false,
        isError: true,
      });
    },
  },
  mounted: function () {},
};
</script>
<style>
.is-live-telemetry-info {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}

.is-live-telemetry-button {
  margin-top: 1rem;
  margin-right: 0.2rem;
}
.is-live-telemetry-content {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.is-live-telemetry-table {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-bottom: 2rem;
}

.is-live-telemetry-modal {
  height: auto;
  max-width: 100%;
  width: 60rem;
  overflow-y: auto;
  overflow-x: auto;
}
</style>