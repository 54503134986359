<template>
  <footer>
    <div class="footer-box">
      <p id="footer-text">
        Copyright © {{ new Date().getFullYear() }}
        <a
          :href="platformProviderURL"
          target="_blank"
        >{{ platform }}</a>. All rights
        reserved.
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {
      platform: process.env.VUE_APP_PLATFORM,
      platformProviderURL: process.env.VUE_APP_PLATFORM_PROVIDER_URL,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./../styles/_variables.scss";
#footer-text {
  font-size: 0.8em;
  background-color: $background;
  width: auto;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 2rem;
}

.footer-box {
  padding-bottom: 0.75rem;
  left: -50%;
  position: relative;
}

.footer-help {
  /*padding-bottom: 0.5rem;*/
  left: -50%;
  position: relative;
}

footer {
  margin-top: auto;
  position: fixed;
  text-align: center;
  bottom: 0;
  left: 50%;
}
</style>

