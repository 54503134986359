<template>
  <div>
    <Modal
      title="Change account password"
      :background-primary="true"
      @close="$parent.close()"
    >
      <div class="is-password-modal">
        <form
          action
          @submit.prevent="request"
        >
          <section class="modal-card-body">
            <b-field label="Password">
              <b-input
                v-model="form.password"
                type="password"
                :value="form.password"
                password-reveal
                placeholder="Your password"
              />
            </b-field>

            <b-field label="Repeat password">
              <b-input
                v-model="form.passwordAgain"
                type="password"
                :value="form.passwordAgain"
                password-reveal
                placeholder="Repeat your password"
              />
            </b-field>
          </section>
          <button class="button is-dark">
            Change password
          </button>
        </form>
      </div>
    </Modal>
  </div>
</template>
<script>
import UsersApi from "@/services/Users";
import Errors from "@/services/Error";
import Modal from "@/components/elements/Modal.vue";
import { mapActions } from "vuex";

export default {
  name: "UsersChangePassword",
  components: {
    Modal,
  },
  data() {
    return {
      form: {
        password: "",
        passwordAgain: "",
      },
    };
  },
  methods: {
    ...mapActions({
      throwGlobalNotification: "throwGlobalNotification",
    }),
    async request() {
      if (this.form.password != this.form.passwordAgain) {
        this.throwGlobalNotification({
          message: "Passwords don't match",
          queue: false,
          isError: true,
        });
      }
      try {
        var results = await UsersApi.userProfile(
          "",
          this.form.password,
          "",
          "PATCH"
        );
      } catch (err) {
        if (err.name == Errors.API.Unauthorized.name) {
          this.throwGlobalNotification({
            message: "Your session has expired, please sign in again",
            queue: false,
            isError: true,
          });
        } else if (err.name == Errors.API.UnexpectedErr.name) {
          this.throwGlobalNotification({
            message: "An unexpected error has occurred",
            queue: false,
            isError: true,
          });
        } else if (err.name == Errors.API.ServerInternalErr.name) {
          this.throwGlobalNotification({
            message: "Internal server error has occurred",
            queue: false,
            isError: true,
          });
        } else {
          this.throwGlobalNotification({
            message: "Couldn't connect to the API. Are you online?",
            queue: false,
            isError: true,
          });
        }
      }
      if (results) {
        if (this.form.password != "" || this.form.passwordAgain != "")
          this.throwGlobalNotification({
            message: "Successfully changed password",
            queue: true,
            isError: false,
          });

        // reset fields
        this.form.password = "";
        this.form.passwordAgain = "";
      }
    },
    // eslint-disable-next-line
    onCopy(e) {
      this.throwGlobalNotification({
        message: "Copied to clipboard",
        queue: true,
        isError: false,
      });
    },
    // eslint-disable-next-line
    onError(e) {
      this.throwGlobalNotification({
        message: "Failed to copy to clipboard",
        queue: false,
        isError: true,
      });
    },
  },
  mounted: function () {},
};
</script>
<style>
.is-password-modal {
  height: 16rem;
  width: auto;
  overflow-y: auto;
}
</style>


