<template>
  <section>
    <div
      class="is-page-modal-title"
      :class="{ 'background-primary': backgroundPrimary }"
    >
      <p>{{ title }}</p>
      <div>
        <slot name="button">
          <b-button
            v-if="(buttonIcon != null && buttonIconPack != null) || buttonText != null"
            type="button"
            class="button is-dark is-rounded"
            size="is-small"
            @click="$emit('click', true)"
          >
            <span class="is-modal-btn-icon">
              <b-icon
                v-if="buttonIcon != null && buttonIconPack != null"
                :pack="buttonIconPack"
                :icon="buttonIcon"
              />
              <span
                v-if="buttonText != null"
                class="is-modal-btn-text"
              >{{ buttonText }}</span>
            </span>
          </b-button>
        </slot>
        <slot name="custom-button" />
        <button
          type="button"
          class="delete"
          :class="(buttonIcon != null && buttonIconPack != null) || buttonText != null ? 'is-modal-delete-btn-adjusted' : ''"
          @click="$emit('close', true)"
        />
      </div>
    </div>
    <div class="modal-card is-page-modal">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: String,
    buttonText: String,
    buttonIconPack: String,
    buttonIcon: String,
    fullscreen: Boolean,
    backgroundPrimary: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "./../../styles/modal.scss";
</style>