<template>
  <Modal
    title="Setting up devices and organisations"
    :background-primary="true"
    @close="$parent.close()"
  >
    <div class="is-modal">
      <b-steps
        v-model="activeStep"
        :animated="true"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
      >
        <b-step-item
          label="Organisation"
          :clickable="true"
        >
          <div class="help-text-box">
            To create your first organisation, click on
            <span class="is-help-icon">
              <b-icon
                icon="building"
                pack="fas"
                custom-size="fa-sm"
              />
              <b>Organisations</b>
            </span> in the navigation bar.
            <br>Click the
            <span class="is-help-icon">
              <b-icon
                icon="plus"
                pack="fas"
                custom-size="fa-sm"
              />
              <b>Create new</b>
            </span> button and create a new one.
            <br>
            <br>Your organisation has to be connected to
            <b>The Things Network</b> to receive any sensor data.
            <br>Find your organisation on the
            <span class="is-help-icon">
              <b-icon
                icon="building"
                pack="fas"
                custom-size="fa-sm"
              />
              <b>Organisations</b>
            </span> page, click on the
            <span class="is-help-icon">
              <b-icon
                pack="fas"
                icon="chevron-circle-down"
                size="fa-xs"
              />
            </span> button and select
            <b>Create authorization credentials</b>. Follow instructions on the screen.
            <br>
            <br>After this the organisation is created and connected to
            <b>The Things Network</b>.
          </div>
        </b-step-item>

        <b-step-item
          label="My Devices"
          :clickable="true"
        >
          <div class="help-text-box">
            To import a device, click on
            <span class="is-help-icon">
              <b-icon
                icon="terminal"
                pack="fas"
                custom-size="fa-sm"
              />
              <b>My Devices</b>
            </span> in the navigation bar.
            <br>Click on the
            <span class="is-help-icon">
              <b-icon
                icon="plus"
                pack="fas"
                custom-size="fa-sm"
              />
              <b>Add</b>
            </span> button to import a new device.
            <br>
            <br>Data received from
            <b>The Things Network</b> will be automatically linked to your devices.
            <br>You will be able to review it later.
          </div>
        </b-step-item>

        <b-step-item
          label="All done!"
          :clickable="true"
          type="is-success"
        >
          <div class="help-text-box">
            You've setup your account.
            <br>All data from your account is now available on the
            <span class="is-help-icon">
              <b-icon
                icon="thermometer-three-quarters"
                pack="fas"
                custom-size="fa-sm"
              />
              <b>Status</b>
            </span> page.
            <br>
            <br>If you need any further help, please contact the
            <b>site administrator</b>.
          </div>
        </b-step-item>
      </b-steps>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal.vue";
export default {
  name: "DevicesOrganisationsDocs",
  components: {
    Modal
  },
  data() {
    return {
      activeStep: 0,
      hasNavigation: true,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      isStepsClickable: true,
      isProfileSuccess: false
    };
  }
};
</script>

<style>
.is-modal {
  height: 25rem;
  overflow-y: auto;
}

.help-text-box {
  font-size: 1.2em;
}

.is-help-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding-bottom: 1px;
}
</style>