import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import VueClipboard from 'vue-clipboard2'
import StatusIndicator from 'vue-status-indicator'
import JsonCSV from 'vue-json-csv'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
// import Bars from 'vuebars'
// import 'buefy/dist/buefy.css';


Vue.use(StatusIndicator)
Vue.use(Buefy)
Vue.use(VueClipboard)
Vue.component('DownloadCsv', JsonCSV)
// Vue.use(Bars)

// eslint-disable-next-line
console.log("FIAP Frontend " + store.state.appVersion)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
