import Api from '@/services/Api'
import Errors from '@/services/Error'
import store from '@/store'
import ReconnectingWebSocket from 'reconnecting-websocket';

export default {
    requestStatus: 0,
    requestError: 0,
    telemetryRequest(payloadID, requestPayloadTransmission, limitResult, reduceResult, startDate, endDate, orderByDate, apiKey = null) {
        var promise = new Promise((resolve, reject) => {
            var telemetryRequest = {
                "payload_id": payloadID,
                "request_payload_transmission": requestPayloadTransmission,
                "limit_result": limitResult,
                "reduce_result": reduceResult,
                "start_date": startDate,
                "end_date": endDate,
                "order_by_captured": orderByDate,
                "metadata": true
            }

            var err // define err
            if (apiKey == null) {
                if (store.state.user.username == null || store.state.user.token == null) {
                    err = new Error("Couldn't send request, no username and/or token")
                    err.name = Errors.API.Unauthorized.name
                    reject(err)
                    return promise
                }
                apiKey = store.state.user.username + ':' + store.state.user.token
            }

            var request = Api.InitRequest(Api.telemetryEndpoint(), "POST", telemetryRequest, apiKey)

            var self = this;

            // make POST request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    telemetryStats(payloadID) {
        var promise = new Promise((resolve, reject) => {
            var err // define err

            if (store.state.user.username == null || store.state.user.token == null) {
                err = new Error("Couldn't send request, no username and/or token")
                err.name = Errors.API.Unauthorized.name
                reject(err)
                return promise
            }

            var apiKey = store.state.user.username + ':' + store.state.user.token

            var request = Api.InitRequest(Api.telemetryStatsEndpoint(payloadID), "GET", "", apiKey)

            var self = this;

            // make POST request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    telemetryStatus(requireAuth, privateEndpoint, enableAll, enableMetadata) {
        var promise = new Promise((resolve, reject) => {
            var err // define err

            var apiKey = ""
            var endpoint = Api.telemetryStatusEndpoint()
            if (requireAuth) {
                if (store.state.user.username == null || store.state.user.token == null) {
                    err = new Error("Couldn't send request, no username and/or token")
                    err.name = Errors.API.Unauthorized.name
                    reject(err)
                    return promise
                }

                apiKey = store.state.user.username + ':' + store.state.user.token

                if (privateEndpoint) {
                    endpoint = Api.telemetryStatusPrivateEndpoint(enableAll, enableMetadata)
                }
            }

            var request = Api.InitRequest(endpoint, "GET", "", apiKey)

            var self = this;

            // make POST request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    telemetryData(telemetryID) {
        var promise = new Promise((resolve, reject) => {
            var apiKey = store.state.user.username + ':' + store.state.user.token
            var request = Api.InitRequest(Api.telemetryDataEndpoint() + telemetryID, "GET", "", apiKey)
            var self = this;

            // eslint-disable-next-line
            var err

            // make request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 403) {
                        var err = new Error("Forbidden")
                        err.name = Errors.API.Forbidden.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    telemetryLatest(uuid, description) {
        var promise = new Promise((resolve, reject) => {
            var telemetryLatestRequest = {
                "description": description
            }

            if (store.state.user.username == null || store.state.user.token == null) {
                err = new Error("Couldn't send request, no username and/or token")
                err.name = Errors.API.Unauthorized.name
                reject(err)
                return promise
            }

            var apiKey = store.state.user.username + ':' + store.state.user.token
            var request = Api.InitRequest(Api.telemetryLatestEndpoint(uuid), "PATCH", telemetryLatestRequest, apiKey)
            var self = this;

            // eslint-disable-next-line
            var err
            // make request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 403) {
                        var err = new Error("Forbidden")
                        err.name = Errors.API.Forbidden.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    subscribeTelemetryDebuggingConnector() {
        return new Promise((resolve, reject) => {
            if (store.state.user.username == null || store.state.user.token == null) {
                let err = new Error("Couldn't send request, no username and/or token")
                err.name = Errors.API.Unauthorized.name
                reject(err)
            }

            const urlProvider = async () => {
                var apiKey = store.state.user.username + ':' + store.state.user.token
                return Api.telemetryDebuggingEndpoint() + "?api_key=" + apiKey
            }

            let connection = new ReconnectingWebSocket(urlProvider)

            resolve(connection)
        })
    }
}