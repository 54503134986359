import Api from '@/services/Api'
import Errors from '@/services/Error'
// import { mapMutations } from 'vuex'
import store from '@/store'

export default {
    requestStatus: 0,
    requestError: 0,
    userPermissions(user_id, organisation_id, level, method) {
        var promise = new Promise((resolve, reject) => {
            var err
            var createRequest = {
                'user_id': user_id,
                'organisation_id': organisation_id,
                'level': level
            }
            var url_user_id = ""

            if (store.state.user.username == null || store.state.user.token == null) {
                err = new Error("Couldn't send request, no username and/or token")
                err.name = Errors.API.Unauthorized.name
                reject(err)
                return promise
            }

            var apiKey = store.state.user.username + ':' + store.state.user.token

            switch (method) {
                case "GET":
                    createRequest = ""
                    url_user_id = user_id
                    break;
                case "PUT":
                    // do nothing, pass createRequest through
                    break;
                default:
                    err = new Error(method + " is not a valid method on this endpoint!")
                    err.name = Errors.API.UserErrors.InvalidMethodErr
                    reject(err)
                    return promise
            }

            var request = Api.InitRequest(Api.userPermissionsEndpoint() + url_user_id, method, createRequest, apiKey)

            var self = this;

            // make POST request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 403) {
                        err = new Error("Forbidden")
                        err.name = Errors.API.Forbidden.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 409) {
                        err = new Error("Conflict")
                        err.name = Errors.API.Conflict.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    organisationPermissions(organisation_id, method) {
        var promise = new Promise((resolve, reject) => {
            var err

            if (store.state.user.username == null || store.state.user.token == null) {
                err = new Error("Couldn't send request, no username and/or token")
                err.name = Errors.API.Unauthorized.name
                reject(err)
                return promise
            }

            var apiKey = store.state.user.username + ':' + store.state.user.token

            switch (method) {
                case "GET":
                    break;
                default:
                    err = new Error(method + " is not a valid method on this endpoint!")
                    err.name = Errors.API.UserErrors.InvalidMethodErr
                    reject(err)
                    return promise
            }

            var request = Api.InitRequest(Api.organisationPermissionsEndpoint() + organisation_id, method, "", apiKey)

            var self = this;

            // make POST request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 403) {
                        var err = new Error("Forbidden")
                        err.name = Errors.API.Forbidden.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    usernamesPermissions(organisation_id, method) {
        var promise = new Promise((resolve, reject) => {
            var err

            if (store.state.user.username == null || store.state.user.token == null) {
                err = new Error("Couldn't send request, no username and/or token")
                err.name = Errors.API.Unauthorized.name
                reject(err)
                return promise
            }

            var apiKey = store.state.user.username + ':' + store.state.user.token

            switch (method) {
                case "GET":
                    break;
                default:
                    err = new Error(method + " is not a valid method on this endpoint!")
                    err.name = Errors.API.UserErrors.InvalidMethodErr
                    reject(err)
                    return promise
            }

            var request = Api.InitRequest(Api.usernamesPermissionsEndpoint() + organisation_id, method, "", apiKey)

            var self = this;

            // make POST request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 403) {
                        var err = new Error("Forbidden")
                        err.name = Errors.API.Forbidden.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    deletePermission(id) {
        var promise = new Promise((resolve, reject) => {
            var apiKey = store.state.user.username + ':' + store.state.user.token
            var request = Api.InitRequest(Api.userPermissionsEndpoint() + id, "DELETE", "", apiKey)
            var self = this;

            // eslint-disable-next-line
            var err

            // make DELETE request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 403) {
                        var err = new Error("Forbidden")
                        err.name = Errors.API.Forbidden.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
}