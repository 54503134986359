<template>
  <Modal
    title="Notifications"
    :background-primary="true"
    @close="$parent.close()"
  >
    <template #button>
      <b-dropdown
        position="is-bottom-left"
        aria-role="list"
      >
        <button
          slot="trigger"
          class="button is-dark is-rounded is-small"
          type="button"
          style="margin-right: 1rem; margin-top: -0.1rem"
        >
          <b-icon
            pack="fas"
            icon="cogs"
          />
          <span>Preferences</span>
        </button>
        <b-dropdown-item
          aria-role="listitem"
          @click="setNotificationClear()"
        >
          <div class="media">
            <b-icon
              class="media-left"
              pack="fas"
              icon="check-double"
            />
            <div class="media-content">
              <h3>Clear device notifications</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item
          aria-role="listitem"
          @click="sendNotificationsEmailEnabled(true); userPreferenceFeedback(true)"
        >
          <div class="media">
            <b-icon
              class="media-left"
              pack="fas"
              icon="user-cog"
            />
            <div class="media-content">
              <h3>Enable email notifications</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item
          aria-role="listitem"
          @click="sendNotificationsEmailEnabled(false); userPreferenceFeedback(false)"
        >
          <div class="media">
            <b-icon
              class="media-left"
              pack="fas"
              icon="user-cog"
            />
            <div class="media-content">
              <h3>Disable email notifications</h3>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <section class="is-notification-content">
      <EmptyMessage
        message="You have no new system notifications!"
        icon-pack="mdi"
        icon="bell-off"
      >
        <b-message
          v-if="!isPopupDisabled()"
          type="is-info"
          aria-close-label="Close message"
          class="is-user-notification"
        >
          To set up devices and organisations you will first have to create a
          <b>TTN</b>
          (
          <a
            href="https://account.thethingsnetwork.org/register"
            target="_blank"
          >The Things Network</a>) account. Then you will be able to add corresponding organisations
          and devices, based on the data received from <b>TTN</b>. For more
          information use the tutorial.
          <br>
          <button
            class="button is-notification-button"
            @click="isTutorialActive = !isTutorialActive"
          >
            <b-icon
              pack="fas"
              icon="question-circle"
            />
            <span>Tutorial</span>
          </button>
          <button
            class="button is-danger is-notification-button"
            @click="disablePopup()"
          >
            <b-icon
              pack="fas"
              icon="times-circle"
            />
            <span>Don't show this again</span>
          </button>
        </b-message>
        <!--
                Super User is internally referenced to as site-admin
        -->
        <b-message
          v-if="hasPermission(dashboardState.permissions, 'site-admin')"
          type="is-info"
          aria-close-label="Close message"
          class="is-user-notification"
        >
          You have
          <b>Super User</b> permissions. Use the <b>Users</b> tab to manage
          users and their permissions.
        </b-message>
      </EmptyMessage>
    </section>
    <div class="divider">
      DEVICE NOTIFICATIONS
    </div>
    <section>
      <b-table
        ref="notifications-table"
        class="is-notification-table"
        :data="
          dashboardState.notifications.length == 0
            ? []
            : dashboardState.notifications
        "
        :bordered="false"
        :striped="true"
        :narrowed="true"
        :hoverable="false"
        :loading="false"
        :focusable="false"
        :mobile-cards="false"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        default-sort="date_created"
        :per-page="4"
        :paginated="true"
      >
        <template slot-scope="props">
          <b-table-column
            field="payload_id"
            label="EUI"
          >
            <a
              v-clipboard:copy="props.row.payload_id"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >{{ props.row.payload_id }}</a>
          </b-table-column>

          <b-table-column
            field="message"
            label="Message"
            sortable
          >
            {{
              props.row.message
            }}
          </b-table-column>

          <b-table-column
            field="organisation_id"
            label="Organisation"
            sortable
          >
            <p
              v-if="
                findByField(
                  dashboardState.organisations,
                  'id',
                  props.row.organisation_id
                ) != null
              "
            >
              {{
                !dashboardState.organisations.length == 0
                  ? findByField(
                    dashboardState.organisations,
                    "id",
                    props.row.organisation_id
                  ).name
                  : props.row.organisation_id
              }}
            </p>
            <span
              v-else
              class="tag is-danger"
            >
              <b>ORGANISATION UNAVAILABLE</b>
            </span>
          </b-table-column>

          <b-table-column
            field="device_id"
            label="Device"
            sortable
          >
            <p
              v-if="
                findByField(
                  dashboardState.devices,
                  'id',
                  props.row.device_id
                ) != null
              "
            >
              {{
                !dashboardState.devices.length == 0
                  ? findByField(
                    dashboardState.devices,
                    "id",
                    props.row.device_id
                  ).name
                  : props.row.device_id
              }}
            </p>
            <span v-else>
              <p
                v-if="
                  findByField(
                    dashboardState.devicesshared,
                    'id',
                    props.row.device_id
                  ) != null
                "
              >
                {{
                  !dashboardState.devicesshared.length == 0
                    ? findByField(
                      dashboardState.devicesshared,
                      "id",
                      props.row.device_id
                    ).name
                    : props.row.device_id
                }}
              </p>
              <b-tooltip
                v-else
                label="Owner of this device has stopped sharing access to it. Please contact the site administator for more information."
                type="is-dark"
                position="is-bottom"
                animated
                multilined
              >
                <span class="tag is-danger">
                  <b>DEVICES PAUSED</b>
                </span>
              </b-tooltip>
            </span>
          </b-table-column>

          <b-table-column
            field="status_code"
            label="Status Code"
            sortable
            centered
          >
            <b-tooltip
              :label="statusCodeTooltip(props.row.status_code)"
              type="is-dark"
              position="is-bottom"
              animated
            >
              <status-indicator
                :status="statusCodeBullet(props.row.status_code)"
              />
            </b-tooltip>
          </b-table-column>
          <b-table-column
            field="date_created"
            label="Triggered"
            sortable
            centered
          >
            <span class="tag is-dark">{{
              new Date(props.row.date_created).toLocaleString()
            }}</span>
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                  icon="access-point"
                  pack="mdi"
                  size="is-large"
                />
              </p>
              <p>There are no device notifications.</p>
            </div>
          </section>
        </template>
      </b-table>
    </section>
    <section>
      <b-modal
        :active.sync="isTutorialActive"
        :can-cancel="false"
        has-modal-card
      >
        <DevicesOrganisationsDocs />
      </b-modal>
    </section>
  </Modal>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import DevicesOrganisationsDocs from "@/components/docs/DevicesOrganisations.vue";
import EmptyMessage from "@/components/elements/EmptyMessage.vue";
import Modal from "@/components/elements/Modal.vue";
import Tools from "@/services/Tools.js";

export default {
  name: "UsersNotification",
  data() {
    return {
      isTutorialActive: false,
      connection: null,
    };
  },
  computed: {
    ...mapState({
      userState: "user",
      dashboardState: "dashboardStates",
    }),
    timeOfDay: {
      get() {
        return this.$store.state.currentTime;
      },
      set(value) {
        this.$store.commit("updateCurrentTime", value);
      },
    },
  },
  components: {
    DevicesOrganisationsDocs,
    EmptyMessage,
    Modal,
  },
  methods: {
    ...mapMutations(["setPopupDisable", "setNotificationClear"]),
    ...mapGetters(["isPopupDisabled"]),
    ...mapActions({
      throwGlobalNotification: "throwGlobalNotification",
      sendNotificationsEmailEnabled: "sendNotificationsEmailEnabled",
    }),
    disablePopup() {
      this.setPopupDisable(true);
    },
    statusCodeBullet(statusCode) {
      if (statusCode == 0) {
        return "positive";
      } else if (statusCode == 1) {
        return "intermediary";
      } else if (statusCode == 2) {
        return "negative";
      }
    },
    statusCodeTooltip(statusCode) {
      if (statusCode == 0) {
        return "OK";
      } else if (statusCode == 1) {
        return "Warning";
      } else if (statusCode == 2) {
        return "Critical";
      } else {
        return "Unknown status!";
      }
    },
    hasPermission: Tools.hasPermission,
    findByField: Tools.findByField,
    // eslint-disable-next-line
    onCopy(e) {
      this.throwGlobalNotification({
        message: "Copied to clipboard",
        queue: true,
        isError: false,
      });
    },
    // eslint-disable-next-line
    onError(e) {
      this.throwGlobalNotification({
        message: "Failed to copy to clipboard",
        queue: false,
        isError: true,
      });
    },
    userPreferenceFeedback(state) {
      this.throwGlobalNotification({
        message: state
          ? "The selected option has been successfully enabled"
          : "The selected option has been successfully disabled",
        queue: true,
        isError: false,
      });
    },
  },
  mounted: function () {},
};
</script>
<style>
.is-user-notification {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}

.is-notification-button {
  margin-top: 1rem;
  margin-right: 0.2rem;
}
.is-notification-content {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.is-notification-table {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-bottom: 2rem;
}
</style>