export default {
    "API": {
        "Unauthorized": {
            name: "Unauthorized"
        },
        "Forbidden": {
            name: "Forbidden"
        },
        "NotFound": {
            name: "NotFound"
        },
        "BadRequest": {
            name: "BadRequest"
        },
        "Conflict":{
            name: "Conflict"
        },
        "ServerInternalErr": {
            name: "ServerInternalErr"
        },
        "UnexpectedErr": {
            name: "UnexpectedErr"
        },
        "UserErrors": {
            "InvalidMethodErr": {
                name: "InvalidMethodErr"
            }
        }
    }
}