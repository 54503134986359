<template>
  <section class="section">
    <div
      v-if="userState.username != null"
      class="logged_in"
    >
      <!-- Notification -->
      <b-notification
        type="is-light"
        :closable="false"
      >
        <p>
          Hi
          <b>{{ userState.username }}</b>! You are already logged in.
        </p>
      </b-notification>
      <!-- Buttons -->
      <div>
        <a
          class="button is-danger is-rounded is-profile-button"
          @click="userLogout"
        >
          <b-icon
            pack="fas"
            icon="sign-out-alt"
          />
          <span>Log out</span>
        </a>
        <a
          v-if="closeButton && userState.username != null"
          class="button is-info is-rounded is-profile-button"
          @click="$parent.close"
        >
          <b-icon
            pack="fas"
            icon="times-circle"
          />
          <span>Close this window</span>
        </a>
      </div>
    </div>
    <b-tabs
      v-if="userState.username == null"
      v-model="activeTab"
      class="modal-card"
      style="width: auto; background-color: white"
    >
      <b-tab-item label="Log in">
        <form
          action
          @submit.prevent="request"
        >
          <section class="modal-card-body">
            <b-field label="Username">
              <b-input
                v-model="form.username"
                type="username"
                :value="form.username"
                placeholder="Your username"
                required
              />
            </b-field>

            <b-field label="Password">
              <b-input
                v-model="form.password"
                type="password"
                :value="form.password"
                password-reveal
                placeholder="Your password"
                required
              />
            </b-field>

            <b-checkbox v-model="form.rememberMeCheckbox">
              Remember me
            </b-checkbox>
          </section>
          <button class="button is-dark">
            Log in
          </button>
        </form>
      </b-tab-item>

      <b-tab-item label="Register">
        <form
          action
          @submit.prevent="request"
        >
          <section class="modal-card-body">
            <b-field label="Email">
              <b-input
                v-model="form.email"
                type="email"
                :value="form.email"
                placeholder="Your email"
                required
              />
            </b-field>
            <b-field label="Username">
              <b-input
                v-model="form.username"
                type="username"
                :value="form.username"
                minlength="4"
                maxlength="50"
                validation-message="No special characters, only English alphabet allowed (4-50 characters)"
                pattern="[a-zA-Z0-9]*"
                placeholder="Your username"
                required
              />
            </b-field>

            <b-field label="Password">
              <b-input
                v-model="form.password"
                type="password"
                :value="form.password"
                minlength="8"
                maxlength="50"
                password-reveal
                placeholder="Your password"
                required
              />
            </b-field>

            <b-checkbox v-model="form.tosCheckbox">
              I accept the Terms of Service
            </b-checkbox>
          </section>
          <button class="button is-dark">
            Register
          </button>
        </form>
      </b-tab-item>
    </b-tabs>
  </section>
</template>
<script>
import UsersApi from "@/services/Users";
import Errors from "@/services/Error";
import { mapState, mapMutations, mapActions } from "vuex";
import router from "@/router";

export default {
  name: "Login",
  components: {},
  props: {
    onLoginClose: Boolean,
    onLogoutClose: Boolean,
    closeButton: Boolean,
  },
  data() {
    return {
      isChangeAuthActive: false,
      jsonResponse: null,
      requestStatus: null,
      requestError: null,
      activeTab: 0,

      form: {
        email: "",
        username: "",
        password: "",
        tosCheckbox: false,
        rememberMeCheckbox: true, // Remember Me is checked by default
      },
    };
  },
  computed: {
    ...mapState({
      userState: "user",
    }),
    ...mapMutations({
      setUserAuth: "setUserAuth",
    }),
    isLoginActive: {
      get() {
        return this.$store.state.loginActive;
      },
      set(value) {
        this.$store.commit("updateLoginActive", value);
      },
    },
    emailLowercase() {
      return this.form.email.toLowerCase().trim();
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      logout: "clearUserAuth",
      getOrganisations: "getOrganisations",
      getDevices: "getDevices",
      getDevicesShared: "getDevicesShared",
      getSharedDevices: "getSharedDevices",
      getUserPermissions: "getUserPermissions",
      getStatus: "getStatus",
      subscribeNotifications: "subscribeNotifications",
      throwGlobalNotification: "throwGlobalNotification",
    }),
    async request() {
      if (this.activeTab == 0) {
        // User login (Method: POST)
        try {
          var results = await UsersApi.userAuth(
            this.form.username,
            this.form.password,
            "",
            "POST",
            {
              saveLocalStorage: this.form.rememberMeCheckbox,
            }
          );
        } catch (err) {
          if (err.name == Errors.API.Unauthorized.name) {
            this.throwGlobalNotification({
              message: "Wrong username or password",
              queue: false,
              isError: true,
            });
          } else if (err.name == Errors.API.Forbidden.name) {
            this.throwGlobalNotification({
              message: "Your account has to be activated by an administrator",
              queue: false,
              isError: true,
            });
          } else if (err.name == Errors.API.UnexpectedErr.name) {
            this.throwGlobalNotification({
              message: "An unexpected error has occurred",
              queue: false,
              isError: true,
            });
          } else if (err.name == Errors.API.ServerInternalErr.name) {
            this.throwGlobalNotification({
              message: "Internal server error has occurred",
              queue: false,
              isError: true,
            });
          } else {
            this.throwGlobalNotification({
              message: "Couldn't connect to the API. Are you online?",
              queue: false,
              isError: true,
            });
          }
        }
        if (results) {
          this.throwGlobalNotification({
            message: "Successfully logged in",
            queue: true,
            isError: false,
          });

          this.getUserPermissions();
          this.getOrganisations();
          this.getDevices();
          this.getDevicesShared();
          this.getSharedDevices();
          this.getStatus();
          this.subscribeNotifications();

          if (this.onLoginClose) {
            // Close the panel after login
            this.$parent.close();
          }
        }
      } else if (this.activeTab == 1) {
        // User registration (Method: PUT)

        // Check if the user agrees to ToS
        if (this.form.tosCheckbox == false) {
          this.throwGlobalNotification({
            message: "You have to accept the Terms of Service to register",
            queue: false,
            isError: true,
          });
          return;
        }
        try {
          results = await UsersApi.userAuth(
            this.form.username,
            this.form.password,
            this.emailLowercase,
            "PUT",
            {}
          );
        } catch (err) {
          if (err.name == Errors.API.Unauthorized.name) {
            this.throwGlobalNotification({
              message: "Wrong username or password",
              queue: false,
              isError: true,
            });
          } else if (err.name == Errors.API.BadRequest.name) {
            this.throwGlobalNotification({
              message: err.message,
              queue: false,
              isError: true,
            });
          } else if (err.name == Errors.API.Conflict.name) {
            this.throwGlobalNotification({
              message: err.message,
              queue: false,
              isError: true,
            });
          } else if (err.name == Errors.API.UnexpectedErr.name) {
            this.throwGlobalNotification({
              message: "An unexpected error has occurred",
              queue: false,
              isError: true,
            });
          } else if (err.name == Errors.API.ServerInternalErr.name) {
            this.throwGlobalNotification({
              message: "Internal server error has occurred",
              queue: false,
              isError: true,
            });
          } else {
            this.throwGlobalNotification({
              message: "Couldn't connect to the API. Are you online?",
              queue: false,
              isError: true,
            });
          }
        }
        if (results) {
          this.throwGlobalNotification({
            message: "Successfully registered",
            queue: true,
            isError: false,
          });
        }
      }

      // Clear the form (except the username)
      this.form.password = this.form.email = "";
    },
    dashboard() {
      router.push({ path: "dashboard" });
      this.isLoginActive = false;
    },
    userLogout() {
      this.logout();

      if (this.onLogoutClose) {
        this.$parent.close();
      }
    },
  },
  watch: {},
};
</script>

<style>
/* Profile buttons */
.is-profile-button {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
</style>