<template>
  <section>
    <div
      v-if="$route.name == 'embed'"
      id="embed"
    >
      <router-view />
    </div>
    <div
      v-else
      id="app"
    >
      <Navbar :login-dropdown="true" />
      <!-- Buefy Loading -->
      <b-loading
        :is-full-page="true"
        :active.sync="isLoadingActive"
        :can-cancel="false"
      />
      <router-view />
      <Footer />
      <b-modal
        :active="!UserLoggedIn"
        :can-cancel="false"
        has-modal-card
      >
        <Login
          :on-login-close="true"
          :close-button="true"
          @loggedIn="$emit('loggedIn')"
        />
      </b-modal>
    </div>
  </section>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import Navbar from "@/components/Navbar.vue";
import Login from "@/components/Login";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Login,
    Footer,
  },
  data() {
    return {
      isLoginActive: true,
    };
  },
  methods: {
    ...mapMutations(["getUserAuthLocalStorage"]),
    ...mapMutations([
      "getPopupDisableLocalStorage",
      "getNotificationClearLocalStorage",
    ]),
    // ...mapMutations(["setPopupDisable"]),
    ...mapActions(["startCurrentTime"]),
    ...mapActions(["startStatusPoll"]),
    ...mapActions({
      getOrganisations: "getOrganisations",
      getDevices: "getDevices",
      getDevicesShared: "getDevicesShared",
      getSharedDevices: "getSharedDevices",
      getStatus: "getStatus",
      getUserPermissions: "getUserPermissions",
      subscribeNotifications: "subscribeNotifications",
    }),
  },
  mounted() {
    // Site initialization
    this.getUserAuthLocalStorage();
    this.getPopupDisableLocalStorage();
    this.getNotificationClearLocalStorage();
    // this.setPopupDisable(true);
    this.startCurrentTime();
    this.startStatusPoll();

    // Check if the user is logged in and the app is not running embedded
    if (this.UserLoggedIn && this.$route.name != "embed") {
      this.getUserPermissions();
      this.getOrganisations();
      this.getDevices();
      this.getDevicesShared();
      this.getSharedDevices();
      this.subscribeNotifications();
      // this.getStatus(); // Not required in this case, due to startStatusPoll()
    }
  },
  computed: {
    ...mapGetters(["UserLoggedIn"]),
    isLoadingActive: {
      get() {
        return this.$store.state.loadingActive;
      },
      set(value) {
        this.$store.commit("updateLoadingActive", value);
      },
    },
  },
};
</script>

<style lang="scss">
@import "./styles/_variables.scss";

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 50px;
  height: 100%;
  width: 100%;
}

/* Normal background */
html {
  background-color: $background !important;
}

#embed {
  background-color: white !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/*
// Colors
$bg-color: $background;
$dot-color: $background-dot;

// Dimensions
$dot-size: 2px;
$dot-space: 22px;

html {
	background:
		linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
		linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
		$dot-color !important;
	background-size: $dot-space $dot-space !important;
}
*/
</style>

