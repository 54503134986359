<template>
  <div>
    <Modal
      title="Change account email address"
      :background-primary="true"
      @close="$parent.close()"
    >
      <div class="is-email-modal">
        <form
          action
          @submit.prevent="request"
        >
          <section class="modal-card-body">
            <b-field label="Email">
              <b-input
                v-model="form.email"
                type="email"
                :value="form.email"
                :placeholder="form.currentEmail"
              />
            </b-field>
          </section>
          <button class="button is-dark">
            Change email address
          </button>
        </form>
      </div>
    </Modal>
  </div>
</template>
<script>
import UsersApi from "@/services/Users";
import Errors from "@/services/Error";
import Modal from "@/components/elements/Modal.vue";
import { mapActions } from "vuex";

export default {
  name: "UsersChangeEmail",
  components: {
    Modal,
  },
  data() {
    return {
      form: {
        email: "",
	currentEmail: "",
      },
    };
  },
  methods: {
    ...mapActions({
      throwGlobalNotification: "throwGlobalNotification",
    }),
    async request() {
      try {
        var results = await UsersApi.userProfile(
          "",
          "",
          this.form.email,
          "PATCH"
        );
      } catch (err) {
        if (err.name == Errors.API.Unauthorized.name) {
          this.throwGlobalNotification({
            message: "Your session has expired, please sign in again",
            queue: false,
            isError: true,
          });
        } else if (err.name == Errors.API.BadRequest.name) {
          this.throwGlobalNotification({
            message: "The email format is invalid",
            queue: false,
            isError: true,
          });
        } else if (err.name == Errors.API.UnexpectedErr.name) {
          this.throwGlobalNotification({
            message: "An unexpected error has occurred",
            queue: false,
            isError: true,
          });
        } else if (err.name == Errors.API.ServerInternalErr.name) {
          this.throwGlobalNotification({
            message: "Internal server error has occurred",
            queue: false,
            isError: true,
          });
        } else {
          this.throwGlobalNotification({
            message: "Couldn't connect to the API. Are you online?",
            queue: false,
            isError: true,
          });
        }
      }
      if (results) {
        if (this.form.email != "") {
          this.throwGlobalNotification({
            message: "Successfully changed email address",
            queue: true,
            isError: false,
          });
        }

        // reset fields
        this.form.email = "";
      }
    },
    async requestCurrentState() {
      try {
        var result = await UsersApi.userProfile("", "", "", "GET");
      } catch (err) {
        if (err.name == Errors.API.Unauthorized.name) {
          this.throwGlobalNotification({
            message: "Your session has expired, please sign in again",
            queue: false,
            isError: true,
          });
        } else if (err.name == Errors.API.BadRequest.name) {
          this.throwGlobalNotification({
            message: "The email format is invalid",
            queue: false,
            isError: true,
          });
        } else if (err.name == Errors.API.UnexpectedErr.name) {
          this.throwGlobalNotification({
            message: "An unexpected error has occurred",
            queue: false,
            isError: true,
          });
        } else if (err.name == Errors.API.ServerInternalErr.name) {
          this.throwGlobalNotification({
            message: "Internal server error has occurred",
            queue: false,
            isError: true,
          });
        } else {
          this.throwGlobalNotification({
            message: "Couldn't connect to the API. Are you online?",
            queue: false,
            isError: true,
          });
        }
      }
      if (result) {
        this.form.currentEmail = result.email;
      }
    },
    // eslint-disable-next-line
    onCopy(e) {
      this.throwGlobalNotification({
        message: "Copied to clipboard",
        queue: true,
        isError: false,
      });
    },
    // eslint-disable-next-line
    onError(e) {
      this.throwGlobalNotification({
        message: "Failed to copy to clipboard",
        queue: false,
        isError: true,
      });
    },
  },
  mounted: function () {
    this.requestCurrentState()
  },
};
</script>
<style>
.is-email-modal {
  height: 11rem;
  width: auto;
  overflow-y: auto;
}
</style>


