export default {
    findByField(obj, field, value) {
        if (obj != null) {
            let i = 0;
            for (i = 0; i < obj.length; i++) {
                var elem = {};
                elem = obj[i]
                if (elem[field] == value) {
                    return elem
                }
            }
        }
        return null;
    },
    timeDiff(prevDate, currentDate) {
        var prevDateSeconds = new Date(prevDate).getTime() / 1000;
        var currentDateSeconds = new Date(currentDate).getTime() / 1000;

        return currentDateSeconds - prevDateSeconds;
    },
    getAdminPermissions(permissions) {
        let i = 0;
        let adminPermissions = []
        for (i = 0; i < permissions.length; i++) {
            if (permissions[i].organisation_id == "00000000-0000-0000-0000-000000000000") {
                adminPermissions.push(permissions[i])
            }
        }
        return adminPermissions
    },
    hasPermission(permissions, level) {
        let i = 0;
        for (i = 0; i < permissions.length; i++) {
            if (permissions[i].organisation_id == "00000000-0000-0000-0000-000000000000" && permissions[i].level == level) {
                return true
            }
        }
        return false
    },
    getAdminPermissionID(permissions, level) {
        let i = 0;
        for (i = 0; i < permissions.length; i++) {
            if (permissions[i].organisation_id == "00000000-0000-0000-0000-000000000000" && permissions[i].level == level) {
                return permissions[i].id
            }
        }
        return "00000000-0000-0000-0000-000000000000"
    },
    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    },
    HSVtoRGB(h, s, v) {
        var r, g, b, i, f, p, q, t;
        if (arguments.length === 1) {
            s = h.s, v = h.v, h = h.h;
        }
        i = Math.floor(h * 6);
        f = h * 6 - i;
        p = v * (1 - s);
        q = v * (1 - f * s);
        t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0: r = v, g = t, b = p; break;
            case 1: r = q, g = v, b = p; break;
            case 2: r = p, g = v, b = t; break;
            case 3: r = p, g = q, b = v; break;
            case 4: r = t, g = p, b = v; break;
            case 5: r = v, g = p, b = q; break;
        }
        return {
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255),
            rgb: "#" + this.componentToHex(Math.round(r * 255)) + this.componentToHex(Math.round(g * 255)) + this.componentToHex(Math.round(b * 255))
        };
    },
    translate(value, leftMin, leftMax, rightMin, rightMax) {
        let leftSpan = leftMax - leftMin;
        let rightSpan = rightMax - rightMin;

        let valueScaled = (value - leftMin) / leftSpan;

        return rightMin + valueScaled * rightSpan;
    },
    /**
     * Recursively flattens a JSON object using dot notation.
     *
     * NOTE: input must be an object as described by JSON spec. Arbitrary
     * JS objects (e.g. {a: () => 42}) may result in unexpected output.
     * MOREOVER, it removes keys with empty objects/arrays as value (see
     * examples bellow).
     *
     * @example
     * // returns {a:1, 'b.0.c': 2, 'b.0.d.e': 3, 'b.1': 4}
     * flatten({a: 1, b: [{c: 2, d: {e: 3}}, 4]})
     * // returns {a:1, 'b.0.c': 2, 'b.0.d.e.0': true, 'b.0.d.e.1': false, 'b.0.d.e.2.f': 1}
     * flatten({a: 1, b: [{c: 2, d: {e: [true, false, {f: 1}]}}]})
     * // return {a: 1}
     * flatten({a: 1, b: [], c: {}})
     *
     * @param obj item to be flattened
     * @param {Array.string} [prefix=[]] chain of prefix joined with a dot and prepended to key
     * @param {Object} [current={}] result of flatten during the recursion
     *
     * @see https://docs.mongodb.com/manual/core/document/#dot-notation
     */
    flatten(obj, prefix, current) {
        prefix = prefix || []
        current = current || {}

        // Remember, null is also an object!
        if (typeof (obj) === 'object' && obj !== null) {
            Object.keys(obj).forEach(key => {
                this.flatten(obj[key], prefix.concat(key), current)
            })
        } else {
            current[prefix.join('.')] = obj
        }

        return current
    },
    copyToClipboard(text) {
        return navigator.clipboard.writeText(text)
    }
}