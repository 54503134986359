<template>
  <div>
    <nav
      class="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <router-link
          class="navbar-item"
          to="/"
        >
          <b-tooltip
            :label="appVersion"
            type="is-dark"
            position="is-bottom"
            animated
          >
            <img
              :src="platformLogo"
              :alt="platform"
            >
          </b-tooltip>
        </router-link>

        <a
          role="button"
          :class="[isNavbarActive ? 'is-active' : '']"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navMenu"
          @click="toggleNavbar"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>
      <div
        id="navMenu"
        class="navbar-menu"
        :class="[isNavbarActive ? 'is-active' : '']"
      >
        <div class="navbar-start">
          <router-link
            v-for="link in activeNavbarLinks"
            :key="link.id"
            class="navbar-item"
            :to="`${link.page}`"
            active-class="is-active"
          >
            <b-icon
              :pack="link.pack"
              :icon="link.icon"
            />
            <span class="navbar-link-text">{{ link.text }}</span>
          </router-link>
        </div>
        <div class="navbar-end">
          <div
            v-if="userState.username != null"
            class="navbar-item"
          >
            <div class="buttons is-centered">
              <a @click="isUsersNotificationActive = true">
                <b-icon
                  :class="
                    notificationCount() > 0
                      ? 'has-badge-rounded has-badge-danger'
                      : ''
                  "
                  :data-badge="
                    notificationCount() > 0 ? notificationCount() : null
                  "
                  pack="fas"
                  icon="bell"
                />
              </a>
            </div>
          </div>
          <div class="navbar-item">
            <div class="buttons is-centered">
              <b-tooltip
                v-if="userState.username == null"
                label="Log in to view sensitive information"
                type="is-dark"
                position="is-left"
                animated
                multilined
                size="is-small"
              >
                <a
                  v-if="userState.username == null"
                  class="button is-dark"
                  @click="isLoginActive = true"
                >
                  <strong>Log in</strong>
                </a>
              </b-tooltip>
              <a
                v-if="userState.username != null && !loginDropdown"
                class="button is-dark"
                @click="isLoginActive = true"
              >
                <b-icon
                  pack="fas"
                  icon="user"
                  size="is-small"
                />
                <strong>{{ userState.username }}</strong>
              </a>
              <b-dropdown
                v-if="userState.username != null && loginDropdown"
                position="is-bottom-left"
                aria-role="list"
              >
                <a
                  slot="trigger"
                  class="button is-dark"
                  role="button"
                >
                  <b-icon
                    pack="fas"
                    icon="user"
                    size="is-small"
                  />
                  <span>
                    <strong>{{ userState.username }}</strong>
                  </span>
                  <b-icon icon="menu-down" />
                </a>
                <b-dropdown-item
                  aria-role="listitem"
                  custom
                >
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <span class="icon">
                          <b-icon
                            pack="fas"
                            icon="key"
                          />
                        </span>
                        <b-tag
                          v-if="
                            hasPermission(
                              dashboardState.permissions,
                              'site-admin'
                            )
                          "
                          type="is-primary"
                        >
                          Super User
                        </b-tag>
                        <b-tag
                          v-else-if="
                            hasPermission(dashboardState.permissions, 'admin')
                          "
                          type="is-primary"
                        >
                          Integrator
                        </b-tag>
                        <b-tag
                          v-else
                          type="is-primary"
                        >
                          Regular User
                        </b-tag>
                      </div>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="isChangeEmailActive = true"
                >
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <span class="icon">
                          <b-icon
                            pack="fas"
                            icon="envelope"
                          />
                        </span>
                        <p>Change email</p>
                      </div>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="isChangePasswordActive = true"
                >
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <span class="icon">
                          <b-icon
                            pack="fas"
                            icon="passport"
                          />
                        </span>
                        <p>Change password</p>
                      </div>
                    </div>
                  </div>
                </b-dropdown-item>

                <hr class="dropdown-divider">

                <b-dropdown-item
                  aria-role="listitem"
                  @click="isManageAPIKeysActive = true"
                >
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <span class="icon">
                          <b-icon
                            pack="fas"
                            icon="shield-alt"
                          />
                        </span>
                        <p>Manage API keys</p>
                      </div>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="hasPermission(dashboardState.permissions, 'site-admin')"
                  aria-role="listitem"
                  @click="isManageWebhooksActive = true"
                >
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <span class="icon">
                          <b-icon
                            pack="fas"
                            icon="link"
                          />
                        </span>
                        <p>Manage webhooks</p>
                      </div>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="hasPermission(dashboardState.permissions, 'site-admin')"
                  aria-role="listitem"
                  @click="
                    isLiveTelemetryDebuggerActive = !isLiveTelemetryDebuggerActive
                  "
                >
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <span class="icon">
                          <b-icon
                            pack="fas"
                            icon="bug"
                          />
                        </span>
                        <p>Live Telemetry Debugger</p>
                      </div>
                    </div>
                  </div>
                </b-dropdown-item>

                <hr class="dropdown-divider">

                <b-dropdown-item
                  aria-role="listitem"
                  :href="euaLoginURL"
                  target="_blank"
                >
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <span class="icon">
                          <b-icon
                            pack="fas"
                            icon="chart-area"
                          />
                        </span>
                        <p>Open {{ euaName }}</p>
                      </div>
                    </div>
                  </div>
                </b-dropdown-item>

                <hr class="dropdown-divider">

                <b-dropdown-item
                  aria-role="listitem"
                  @click="isTutorialActive = true"
                >
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <span class="icon">
                          <b-icon
                            pack="fas"
                            icon="question-circle"
                          />
                        </span>
                        <p>Tutorial</p>
                      </div>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="logout"
                >
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <span class="icon">
                          <b-icon
                            pack="fas"
                            icon="sign-out-alt"
                          />
                        </span>
                        <p>Log out</p>
                      </div>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <b-modal
      :active.sync="isLoginActive"
      :can-cancel="false"
      has-modal-card
    >
      <Login
        :on-login-close="true"
        :on-logout-close="true"
        :close-button="true"
      />
    </b-modal>
    <b-modal
      :active.sync="isTutorialActive"
      :can-cancel="true"
      has-modal-card
    >
      <DevicesOrganisationsDocs />
    </b-modal>
    <b-modal
      :active.sync="isChangeEmailActive"
      :can-cancel="true"
      has-modal-card
    >
      <UsersChangeEmailComponent />
    </b-modal>
    <b-modal
      :active.sync="isChangePasswordActive"
      :can-cancel="true"
      has-modal-card
    >
      <UsersChangePasswordComponent />
    </b-modal>
    <b-modal
      :active.sync="isManageAPIKeysActive"
      :can-cancel="true"
      has-modal-card
    >
      <UsersManageAPIKeysComponent />
    </b-modal>
    <b-modal
      :active.sync="isManageWebhooksActive"
      :can-cancel="true"
      has-modal-card
    >
      <ManageWebhooksComponent />
    </b-modal>
    <b-modal
      :active.sync="isLiveTelemetryDebuggerActive"
      :can-cancel="true"
      has-modal-card
    >
      <UsersLiveTelemetryComponent />
    </b-modal>
    <b-modal
      :active.sync="isUsersNotificationActive"
      :can-cancel="true"
      has-modal-card
    >
      <UsersNotificationComponent />
    </b-modal>
  </div>
</template>

<script>
import Login from "@/components/Login";
import Tools from "@/services/Tools.js";
import Modal from "@/components/elements/Modal.vue";
import DevicesOrganisationsDocs from "@/components/docs/DevicesOrganisations.vue";
import UsersChangeEmailComponent from "@/components/UsersChangeEmail.vue";
import UsersChangePasswordComponent from "@/components/UsersChangePassword.vue";
import UsersManageAPIKeysComponent from "@/components/UsersManageAPIKeys.vue";
import UsersLiveTelemetryComponent from "@/components/UsersLiveTelemetry.vue";
import UsersNotificationComponent from "@/components/UsersNotification.vue";
import ManageWebhooksComponent from "@/components/ManageWebhooks.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "Navbar",
  components: {
    Login,
    Modal,
    DevicesOrganisationsDocs,
    UsersChangeEmailComponent,
    UsersChangePasswordComponent,
    UsersManageAPIKeysComponent,
    UsersLiveTelemetryComponent,
    UsersNotificationComponent,
    ManageWebhooksComponent,
  },
  props: {
    loginDropdown: Boolean,
  },
  computed: {
    ...mapGetters(["UserLoggedIn", "appVersion", "externalServiceQueryString"]),
    ...mapState({
      userState: "user",
      dashboardState: "dashboardStates",
    }),
    isLoginActive: {
      get() {
        return this.$store.state.loginActive;
      },
      set(value) {
        this.$store.commit("updateLoginActive", value);
      },
    },
    activeNavbarLinks: function () {
      var UserLoggedIn = this.UserLoggedIn;
      var permissions = this.dashboardState.permissions;
      var hasPermission = this.hasPermission;
      return this.links.filter(function (l) {
        if (l.isAdministrator) {
          return (
            hasPermission(permissions, "site-admin") && UserLoggedIn && l.active
          );
        }

        if (l.isLoggedIn) {
          return UserLoggedIn && l.active;
        }

        return l.active;
      });
    },
    euaLoginURL: function () {
      return this.euaBaseURL + "/login" + this.externalServiceQueryString;
    },
  },
  methods: {
    ...mapActions({
      logout: "clearUserAuth",
    }),
    ...mapGetters(["notificationCount"]),
    toggleNavbar() {
      this.isNavbarActive = !this.isNavbarActive;
    },
    hasPermission: Tools.hasPermission,
  },
  data() {
    return {
      // isLoginActive: false,
      isNavbarActive: false,
      isTutorialActive: false,
      isChangeEmailActive: false,
      isChangePasswordActive: false,
      isManageAPIKeysActive: false,
      isManageWebhooksActive: false,
      isLiveTelemetryDebuggerActive: false,
      isUsersNotificationActive: false,
      platform: process.env.VUE_APP_PLATFORM,
      platformLogo: process.env.VUE_APP_PLATFORM_LOGO,
      euaName: process.env.VUE_APP_EUA_NAME,
      euaBaseURL: process.env.VUE_APP_EUA_BASE_URL,
      links: [
        {
          id: 0,
          active: true,
          isLoggedIn: true,
          isAdministrator: false,
          text: "Data",
          page: "/",
          pack: "fas",
          icon: "thermometer-three-quarters",
        },
        {
          id: 1,
          active: true,
          isLoggedIn: true,
          isAdministrator: false,
          text: "Status",
          page: "/status",
          pack: "fas",
          icon: "align-left",
        },
        {
          id: 2,
          active: true,
          isLoggedIn: false,
          isAdministrator: false,
          text: "Device Map",
          page: "/devicemap",
          pack: "fas",
          icon: "map-marked",
        },
        {
          id: 3,
          active: true,
          isLoggedIn: true,
          isAdministrator: false,
          text: "My Devices",
          page: "/mydevices",
          pack: "fas",
          icon: "microchip",
        },
        {
          id: 4,
          active: true,
          isLoggedIn: true,
          isAdministrator: false,
          text: "Organisations",
          page: "/organisations",
          pack: "fas",
          icon: "building",
        },
        {
          id: 5,
          active: true,
          isLoggedIn: true,
          isAdministrator: true, // currently only for administrators - since the API access is allowed, this will be available later
          text: "Device Types",
          page: "/devicetypes",
          pack: "fas",
          icon: "file-code",
        },
        {
          id: 6,
          active: true,
          isLoggedIn: true,
          isAdministrator: true,
          text: "Users",
          page: "/users",
          pack: "fas",
          icon: "user-shield",
        },
      ],
    };
  },
};
</script>

<style>
.navbar-link-text {
  padding-left: 0.5rem;
}

.navbar-brand {
  padding-left: 1rem;
}
</style>
