import Api from '@/services/Api'
import Errors from '@/services/Error'
import store from '@/store'

export default {
    requestStatus: 0,
    requestError: 0,
    webhook(id, payload, method) {
        var promise = new Promise((resolve, reject) => {
            var apiKey = store.state.user.username + ':' + store.state.user.token
            var request = Api.InitRequest(Api.webhookEndpoint(id), method, payload, apiKey)
            var self = this;

            var err

            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.text();
                })
                .then(text => {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(text.length ? JSON.parse(text) : {})
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    }
}
