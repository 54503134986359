import Api from '@/services/Api'
import Errors from '@/services/Error'
// import { mapMutations } from 'vuex'
import store from '@/store'

export default {
    requestStatus: 0,
    requestError: 0,
    devices(name, description, organisation, isShared, method) {
        var promise = new Promise((resolve, reject) => {
            var err
            var shared = isShared ? "shared" : ""
            var createRequest = {
                'name': name,
                'description': description,
                'organisation': organisation
            }

            if (store.state.user.username == null || store.state.user.token == null) {
                err = new Error("Couldn't send request, no username and/or token")
                err.name = Errors.API.Unauthorized.name
                reject(err)
                return promise
            }

            var apiKey = store.state.user.username + ':' + store.state.user.token

            switch (method) {
                case "GET":
                    createRequest = ""
                    break;
                case "PUT":
                    // do nothing
                    break;
                default:
                    err = new Error(method + " is not a valid method on this endpoint!")
                    err.name = Errors.API.UserErrors.InvalidMethodErr
                    reject(err)
                    return promise
            }

            var request = Api.InitRequest(Api.devicesEndpoint(shared), method, createRequest, apiKey)

            var self = this;

            // make POST request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 400) {
                        err = new Error("Bad request")
                        err.name = Errors.API.BadRequest.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 409) {
                        err = new Error("Conflict")
                        err.name = Errors.API.Conflict.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })

        return promise

    },
    sharedDevices(name, deviceID, organisationID, sharedDeviceID, method) {
        var promise = new Promise((resolve, reject) => {
            var err
            var sharedDevID = ""
            var createRequest = {
                'name': name,
                'device_id': deviceID,
                'organisation_id': organisationID
            }

            if (store.state.user.username == null || store.state.user.token == null) {
                err = new Error("Couldn't send request, no username and/or token")
                err.name = Errors.API.Unauthorized.name
                reject(err)
                return promise
            }

            var apiKey = store.state.user.username + ':' + store.state.user.token

            switch (method) {
                case "GET":
                    createRequest = ""
                    break;
                case "PUT":
                    // do nothing
                    break;
                case "PATCH":
                    createRequest.device_id = "00000000-0000-0000-0000-000000000000"
                    createRequest.organisation_id = "00000000-0000-0000-0000-000000000000"
                    sharedDevID = sharedDeviceID
                    break;
                case "DELETE":
                    createRequest = ""
                    sharedDevID = sharedDeviceID
                    break;
                default:
                    err = new Error(method + " is not a valid method on this endpoint!")
                    err.name = Errors.API.UserErrors.InvalidMethodErr
                    reject(err)
                    return promise
            }

            var request = Api.InitRequest(Api.sharedDevicesEndpoint(sharedDevID), method, createRequest, apiKey)

            var self = this;

            // make POST request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })

        return promise

    },
    updateDevice(name, description, devPublic, uuid) {
        var promise = new Promise((resolve, reject) => {
            var patchRequest = {
                'name': name,
                'description': description,
                'public': devPublic
            }

            var apiKey = store.state.user.username + ':' + store.state.user.token
            var request = Api.InitRequest(Api.devicesEndpoint("") + uuid, "PATCH", patchRequest, apiKey)
            var self = this;

            var err

            // make PATCH request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 400) {
                        err = new Error("Bad request")
                        err.name = Errors.API.BadRequest.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 409) {
                        err = new Error("Conflict")
                        err.name = Errors.API.Conflict.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    updateDeviceShared(shared, imgURL, updateShared, updateImgURL, uuid) {
        var promise = new Promise((resolve, reject) => {
            var patchRequest = {
                'shared': shared,
                'img_url': imgURL,
                'update_shared': updateShared,
                'update_img_url': updateImgURL
            }

            var apiKey = store.state.user.username + ':' + store.state.user.token
            var request = Api.InitRequest(Api.devicesEndpoint("") + uuid, "PATCH", patchRequest, apiKey)
            var self = this;

            var err

            // make PATCH request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    deleteDevice(uuid) {
        var promise = new Promise((resolve, reject) => {
            var apiKey = store.state.user.username + ':' + store.state.user.token
            var request = Api.InitRequest(Api.devicesEndpoint("") + uuid, "DELETE", "", apiKey)
            var self = this;

            var err

            // make DELETE request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    },
    descriptorDevice(uuid, descriptorUUID, payload, method) {
        var promise = new Promise((resolve, reject) => {
            var apiKey = store.state.user.username + ':' + store.state.user.token
            var request = Api.InitRequest(Api.devicesEndpoint("") + uuid + "/descriptor" + "/" + descriptorUUID, method, payload, apiKey)
            var self = this;

            var err

            // make request to the server via fetch()
            fetch(request)
                .then(response => {
                    self.requestStatus = response.status;
                    return response.json();
                })
                .then(function (data) {
                    if (self.requestStatus == 401) {
                        err = new Error("Token has expired, sign in.")
                        err.name = Errors.API.Unauthorized.name
                        // delete user token
                        store.dispatch("clearUserAuth")
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 400) {
                        err = new Error("Bad request")
                        err.name = Errors.API.BadRequest.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 409) {
                        err = new Error("Conflict")
                        err.name = Errors.API.Conflict.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 500) {
                        err = new Error("Unexpected internal server error has occurred")
                        err.name = Errors.API.ServerInternalErr.name
                        reject(err)
                        return promise
                    } else if (self.requestStatus == 200) {
                        resolve(data)
                        return promise
                    } else {
                        err = new Error("Unexpected error has occurred")
                        err.name = Errors.API.UnexpectedErr.name
                        reject(err)
                        return promise
                    }
                })
                .catch(error => {
                    self.requestError = error;
                    reject(error)
                });
        })
        return promise
    }
}