<template>
  <div>
    <Modal
      title="Manage account API keys"
      :background-primary="true"
      button-icon-pack="fas"
      button-icon="plus"
      button-text="Add"
      @close="$parent.close()"
      @click="requestAPITokenAction('', 'CREATE')"
    >
      <div class="is-API-modal">
        <b-table
          ref="apitable"
          :data="!APIstate.hasData ? [] : APIstate.data"
          :bordered="false"
          :striped="true"
          :narrowed="true"
          :hoverable="false"
          :loading="!APIstate.hasData"
          :focusable="false"
          :mobile-cards="false"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort-direction="desc"
          default-sort="date_created"
        >
          <template slot-scope="props">
            <b-table-column
              field="manage-btn"
              label
              centered
            >
              <b-dropdown aria-role="list">
                <a
                  slot="trigger"
                  role="button"
                  @click="activeAPITokenUUID=props.row.id"
                >
                  <b-icon
                    pack="fas"
                    icon="chevron-circle-down"
                  />
                </a>
                <b-dropdown-item
                  v-if="!props.row.enabled"
                  aria-role="listitem"
                  @click="requestAPITokenAction(activeAPITokenUUID, 'ENABLE')"
                >
                  <div class="media">
                    <b-icon
                      class="media-left"
                      pack="fas"
                      icon="wrench"
                    />
                    <div class="media-content">
                      <h3>Enable</h3>
                      <small>Enables the API key</small>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.enabled"
                  aria-role="listitem"
                  @click="requestAPITokenAction(activeAPITokenUUID, 'DISABLE')"
                >
                  <div class="media">
                    <b-icon
                      class="media-left"
                      pack="fas"
                      icon="wrench"
                    />
                    <div class="media-content">
                      <h3>Disable</h3>
                      <small>Disables the API key</small>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="requestAPITokenAction(activeAPITokenUUID, 'DELETE')"
                >
                  <div class="media">
                    <b-icon
                      class="media-left"
                      pack="fas"
                      icon="trash-alt"
                    />
                    <div class="media-content">
                      <h3>Delete</h3>
                      <small>The API key will be deleted</small>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </b-table-column>
            <b-table-column
              v-if="showIds"
              field="id"
              label="ID"
            >
              <a
                v-clipboard:copy="props.row.id"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >{{ props.row.id }}</a>
            </b-table-column>
            <b-table-column
              field="token"
              label="Token"
              centered
            >
              <b-tooltip
                label="Copy to clipboard"
                animated
              >
                <span
                  v-clipboard:copy="props.row.token"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  class="button is-small"
                >
                  <b-icon
                    pack="fas"
                    icon="copy"
                    size="is-small"
                  />
                </span>
              </b-tooltip>
            </b-table-column>
            <b-table-column
              field="enabled"
              label="Enabled"
              centered
              sortable
            >
              <status-indicator :status="(props.row.enabled) ? 'positive' : 'negative'" />
            </b-table-column>
            <b-table-column
              field="date_created"
              label="Created"
              centered
              sortable
            >
              <span class="tag is-dark">{{ new Date(props.row.date_created).toLocaleString() }}</span>
            </b-table-column>
            <b-table-column
              field="date_modified"
              label="Modified"
              centered
              sortable
            >
              <span class="tag is-dark">{{ new Date(props.row.date_modified).toLocaleString() }}</span>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  />
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </Modal>
  </div>
</template>
<script>
import UsersApi from "@/services/Users";
import Modal from "@/components/elements/Modal.vue";
import { mapActions } from "vuex";

export default {
  name: "UsersManageAPIKeys",
  components: {
    Modal,
  },
  props: {
    showIds: String,
  },
  data() {
    return {
      activeAPITokenUUID: "00000000-0000-0000-0000-000000000000",
      APIstate: {
        hasData: false,
        data: null,
      },
    };
  },
  methods: {
    ...mapActions({
      throwGlobalNotification: "throwGlobalNotification",
    }),
    requestAPITokens() {
      UsersApi.userAPI("", "", "GET")
        .then((response) => {
          this.APIstate.data = response;
          this.APIstate.hasData = true;
        })
        .catch((err) => {
          this.throwGlobalNotification({
            message: "An error has occurred: " + err.name,
            queue: false,
            isError: true,
          });
        });
    },
    requestAPITokenAction(apiUUID, action) {
      var payload;

      switch (action) {
        case "CREATE":
          UsersApi.userAPI("", "", "PUT")
            .then(() => {
              this.requestAPITokens();
            })
            .catch((err) => {
              this.throwGlobalNotification({
                message: "An error has occurred: " + err.name,
                queue: false,
                isError: true,
              });
            });
          break;
        case "ENABLE":
          payload = {
            enabled: true,
          };

          UsersApi.userAPI(apiUUID, payload, "PATCH")
            .then(() => {
              this.requestAPITokens();
            })
            .catch((err) => {
              this.throwGlobalNotification({
                message: "An error has occurred: " + err.name,
                queue: false,
                isError: true,
              });
            });
          break;
        case "DISABLE":
          payload = {
            enabled: false,
          };

          UsersApi.userAPI(apiUUID, payload, "PATCH")
            .then(() => {
              this.requestAPITokens();
            })
            .catch((err) => {
              this.throwGlobalNotification({
                message: "An error has occurred: " + err.name,
                queue: false,
                isError: true,
              });
            });
          break;
        case "DELETE":
          UsersApi.userAPI(apiUUID, "", "DELETE")
            .then(() => {
              // reset states
              this.requestAPITokens();
            })
            .catch((err) => {
              this.throwGlobalNotification({
                message: "An error has occurred: " + err.name,
                queue: false,
                isError: true,
              });
            });
          break;
      }
    },
    // eslint-disable-next-line
    onCopy(e) {
      this.throwGlobalNotification({
        message: "Copied to clipboard",
        queue: true,
        isError: false,
      });
    },
    // eslint-disable-next-line
    onError(e) {
      this.throwGlobalNotification({
        message: "Failed to copy to clipboard",
        queue: false,
        isError: true,
      });
    },
  },
  mounted() {
    this.requestAPITokens();
  },
};
</script>
<style>
.is-API-modal {
  height: 22rem;
  max-width: 100%;
  width: 32rem;
  overflow-y: auto;
  overflow-x: auto;
}
</style>


