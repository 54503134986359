<template>
  <div>
    <Modal
      title="Manage webhooks"
      :background-primary="true"
      button-icon-pack="mdi"
      :button-icon="WebhookFormState.active ? 'minus' : 'plus'"
      :button-text="WebhookFormState.active ? 'Close' : 'Add'"
      @close="$parent.close()"
      @click="WebhookFormState.active = !WebhookFormState.active"
    >
      <div class="is-webhook-modal">
        <!-- Form -->
        <form
          v-if="WebhookFormState.active"
          class="is-webhook-form"
          @submit.prevent="createWebhook(WebhookFormState.formValues); WebhookFormState.active = false"
        >
          <!-- Elements -->
          <p>Create a new webhook</p>
          <b-field>
            <b-input
              v-model="WebhookFormState.formValues.name"
              placeholder="Webhook name"
              minlength="3"
              maxlength="50"
              type="text"
              icon="tag"
              required
            />
          </b-field>
          <b-field>
            <b-input
              v-model="WebhookFormState.formValues.description"
              placeholder="Webhook description"
              maxlength="5000"
              type="text"
              icon="information"
            />
          </b-field>
          <b-field>
            <b-input
              v-model="WebhookFormState.formValues.uri"
              placeholder="Webhook URI"
              type="url"
              icon="link"
              required
            />
          </b-field>
          <b-field>
            <b-taginput
              v-model="WebhookFormState.formValues.requestHeaders"
              placeholder="Add request headers (format as key:value)"
              icon="label"
            />
          </b-field>
          <b-field>
            <b-switch
              v-model="WebhookFormState.formValues.acceptsData"
              type="is-success"
            >
              Accepts data
            </b-switch>
          </b-field>
          <b-field>
            <b-switch
              v-model="WebhookFormState.formValues.acceptsMetadata"
              type="is-success"
            >
              Accepts metadata
            </b-switch>
          </b-field>
          <b-field>
            <b-switch
              v-model="WebhookFormState.formValues.acceptsNotifications"
              type="is-success"
            >
              Accepts notifications
            </b-switch>
          </b-field>
          <b-field>
            <b-button
              rounded
              native-type="submit"
            >
              Create
            </b-button>
          </b-field>
        </form>
        <b-table
          v-else
          ref="webhook-table"
          :data="!WebhookDataState.hasData ? [] : WebhookDataState.data"
          :bordered="false"
          :striped="true"
          :narrowed="true"
          :hoverable="false"
          :loading="!WebhookDataState.hasData"
          :focusable="false"
          :mobile-cards="false"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort-direction="desc"
          default-sort="date_created"
        >
          <template slot-scope="props">
            <b-table-column
              field="manage-btn"
              label
              centered
            >
              <b-dropdown aria-role="list">
                <a
                  slot="trigger"
                  role="button"
                  @click="activeWebhookID=props.row.id"
                >
                  <b-icon
                    pack="fas"
                    icon="chevron-circle-down"
                  />
                </a>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="deleteWebhook(activeWebhookID)"
                >
                  <div class="media">
                    <b-icon
                      class="media-left"
                      pack="fas"
                      icon="trash-alt"
                    />
                    <div class="media-content">
                      <h3>Delete</h3>
                      <small>The webhook will be deleted</small>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </b-table-column>
            <b-table-column
              v-if="showIds"
              field="id"
              label="ID"
            >
              <a
                v-clipboard:copy="props.row.id"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >{{ props.row.id }}</a>
            </b-table-column>
            <b-table-column
              field="name"
              label="Name"
              centered
              sortable
            >
              {{ props.row.name }}
            </b-table-column>
            <b-table-column
              field="description"
              label="Description"
              centered
              sortable
            >
              {{ props.row.description }}
            </b-table-column>
            <b-table-column
              field="uri"
              label="URI"
            >
              {{ props.row.endpoint.uri }}
            </b-table-column>
            <b-table-column
              field="headers"
              label="Headers"
              centered
            >
              <span
                v-for="(value, key) in props.row.endpoint.header"
                :key="key"
              >
                {{ key }}: {{ value }} 
              </span>
            </b-table-column>
            <b-table-column
              field="accepts_data"
              label="Data"
              centered
              sortable
            >
              <status-indicator :status="(props.row.endpoint.accepts_data) ? 'positive' : 'negative'" />
            </b-table-column>
            <b-table-column
              field="accepts_metadata"
              label="Metadata"
              centered
              sortable
            >
              <status-indicator :status="(props.row.endpoint.accepts_metadata) ? 'positive' : 'negative'" />
            </b-table-column>
            <b-table-column
              field="accepts_notifications"
              label="Notifications"
              centered
              sortable
            >
              <status-indicator :status="(props.row.endpoint.accepts_notifications) ? 'positive' : 'negative'" />
            </b-table-column>
            <b-table-column
              field="date_created"
              label="Created"
              centered
              sortable
            >
              <span class="tag is-dark">{{ new Date(props.row.date_created).toLocaleString() }}</span>
            </b-table-column>
            <b-table-column
              field="date_modified"
              label="Modified"
              centered
              sortable
            >
              <span class="tag is-dark">{{ new Date(props.row.date_modified).toLocaleString() }}</span>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="emoticon-sad"
                    size="is-large"
                  />
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from "@/components/elements/Modal.vue";
import WebhookApi from "@/services/Webhook";
import { mapActions } from "vuex";

export default {
  name: "ManageWebhooks",
  components: {
    Modal,
  },
  props: {
    showIds: {
      type: Boolean,
      default: false
    },
    organisationId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      WebhookDataState: {
        hasData: false,
        data: null,
      },
      WebhookFormState: {
        active: false,
        formValues: {
          name: "",
          description: "",
          uri: "",
          requestHeaders: [],
          acceptsData: true,
          acceptsMetadata: true,
          acceptsNotifications: false,
        }
      },
      activeWebhookID: ""
    };
  },
  mounted() {
    this.requestWebhookData();
  },
  methods: {
    ...mapActions({
      throwGlobalNotification: "throwGlobalNotification",
    }),
    requestWebhookData() {
      WebhookApi.webhook(this.organisationId, "", "GET").then(response => {
        if (response == null) {
          this.WebhookDataState.data = [];
          this.WebhookDataState.hasData = true;
          return
        }
        this.WebhookDataState.data = response;
        this.WebhookDataState.hasData = true;
      }).catch(err => {
        this.throwGlobalNotification({
          message: "An error has occurred: " + err.name,
          queue: false,
          isError: true,
        })
      })
    },
    createWebhook(values) {
      let headers = {}
      values.requestHeaders.forEach(element => {
        let header = element.split(':');
        if (header.length != 2) {
          return;
        }

        let key = header[0];
        let value = header[1];
        if (key.length < 1 || value.length < 1) {
          return;
        }

        headers[key] = value;
      });

      let endpointPayload = {
        uri: values.uri,
        header: headers,
        accepts_data: values.acceptsData,
        accepts_metadata: values.acceptsMetadata,
        accepts_notifications: values.acceptsNotifications,
      };

      let webhookPayload = {
        name: values.name,
        description: values.description,
        organisation_id: this.organisationId,
        endpoint: endpointPayload,
      };

      WebhookApi.webhook(null, webhookPayload, "PUT").then(response => {
        this.requestWebhookData() // request the webhook data, as it has changed
      }).catch(err => {
        this.throwGlobalNotification({
          message: "An error has occurred: " + err.name,
          queue: false,
          isError: true,
        })
      })
    },
    deleteWebhook(id) {
      WebhookApi.webhook(id, "", "DELETE").then(response => {
        this.requestWebhookData() // request the webhook data, as it has changed
      }).catch(err => {
        this.throwGlobalNotification({
          message: "An error has occurred: " + err.name,
          queue: false,
          isError: true,
        })
      })
    },
    // eslint-disable-next-line
    onCopy(e) {
      this.throwGlobalNotification({
        message: "Copied to clipboard",
        queue: true,
        isError: false,
      });
    },
    // eslint-disable-next-line
    onError(e) {
      this.throwGlobalNotification({
        message: "Failed to copy to clipboard",
        queue: false,
        isError: true,
      });
    },
  },
};
</script>
<style>
.is-webhook-modal {
  height: 25rem;
  max-width: 100%;
  width: 40rem;
  overflow-y: auto;
  overflow-x: auto;
}

.is-webhook-form {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.25rem;
}
</style>


