import store from '@/store'

export default {
    baseURL: store.state.apiBaseURL,
    webSocketBaseURL: store.state.apiWSBaseURL,
    usersEndpoint() {
        return this.baseURL + "/users"
    },
    usersProfileEndpoint() {
        return this.baseURL + "/users/profile"
    },
    usersResolveEndpoint() {
        return this.baseURL + "/users/resolve/"
    },
    organisationsEndpoint() {
        return this.baseURL + "/organisations/"
    },
    devicesEndpoint(uuid) {
        return this.baseURL + "/devices/" + uuid
    },
    sharedDevicesEndpoint(uuid) {
        return this.baseURL + "/shareddevices/" + uuid
    },
    userPermissionsEndpoint() {
        return this.baseURL + "/permissions/user/"
    },
    organisationPermissionsEndpoint() {
        return this.baseURL + "/permissions/organisation/"
    },
    usernamesPermissionsEndpoint() {
        return this.baseURL + "/permissions/usernames/"
    },
    adminUserEndpoint() {
        return this.baseURL + "/admin/user/"
    },
    telemetryEndpoint() {
        return this.baseURL + "/telemetry/resource"
    },
    telemetryDataEndpoint() {
        return this.baseURL + "/telemetry/data/"
    },
    telemetryStatsEndpoint(payloadID) {
        return this.baseURL + "/telemetry/stats/" + payloadID
    },
    telemetryStatusEndpoint() {
        return this.baseURL + "/telemetry/status"
    },
    telemetryStatusPrivateEndpoint(enableAll, enableMetadata) {
        return this.baseURL + "/telemetry/status/private" + (enableAll ? "/all" : "") + (enableMetadata && !enableAll ? "/metadata" : "")
    },
    telemetryLatestEndpoint(uuid) {
        return this.baseURL + "/telemetry/latest/" + uuid
    },
    webhookEndpoint(id) {
        return this.baseURL + "/webhook" + (id ? "/" + id : "")
    },
    telemetryDebuggingEndpoint() {
        return this.webSocketBaseURL + "/telemetry/debugging"
    },
    notificationsEndpoint() {
        return this.webSocketBaseURL + "/notifications"
    },
    InitRequest(URL, method, jsonBody, apiKey) {
        var headers
        if (apiKey == "") {
            headers = new Headers({
                'Content-Type': 'application/json',
            })
        } else {
            headers = new Headers({
                'Content-Type': 'application/json',
                'X-API-Key': apiKey
            })
        }

        var request = {
            method: method,
            mode: 'cors',
            redirect: 'follow',
            headers: headers
        }

        if (!(jsonBody == "")) {
            request.body = JSON.stringify(jsonBody)
        }


        return new Request(URL, request)

    }
}
